/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, forwardRef } from 'react';
import { Grid, TextInput, Text, Button, MultiSelect, Select } from '@mantine/core';
import { ResourceType } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPatientDetails, updatePatientResource } from '../utils/util';
import icdCodes from './../seeds/icd-codes.json';
import otherIcdCodes from './../seeds/other-icd-codes.json';
import { ICDCodeProps, defaultRaceOptions, gendersList } from '../constants';
import { cleanResource } from './utils';
import { showNotification } from '@mantine/notifications';
import { DateInput } from '@mantine/dates';

const PatientEditPage = () => {
  const medplum = useMedplum();
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const navigate = useNavigate();
  const [diagnosesList, setDiagnosesList] = useState<any[]>([]);
  const [tempDiagnosesList, setTempDignosesList] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [otherDiagnosesList, setOtherDiagnosesList] = useState<any[]>([]);
  const [tempOtherDiagnosesList, setTempOtherDiagnosesList] = useState<any[]>([]);
  const [selectedOtherOptions, setSelectedOtherOptions] = useState<any[]>([]);
  const [otherSearchQuery, setOtherSearchQuery] = useState<string>('');
  const [patientDetails, setPatientDetails] = useState<any>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedRace, setSelectedRace] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    mentalHealthDiagnoses: '',
    otherDiagnoses: '',
    race: '',
    mobile: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  });

  useEffect(() => {
    getPatientDetails(medplum, id)
      .then((response: any) => {
        setPatientDetails(response);
        setFormData((prevFormData: any) => {
          return {
            ...prevFormData,
            firstName: response?.name?.[0].given[0].split(' ')[0],
            lastName: response?.name?.[0].family ? response?.name?.[0]?.family : response?.name?.[0].given[0].split(' ')[1],
            birthDate: response?.birthDate,
            gender: response?.gender,
            mobile:
              response?.telecom?.length > 0
                ? response?.telecom?.find((item: any) => item.system === 'phone').value
                : '',
            email:
              response?.telecom?.length > 0
                ? response?.telecom?.find((item: any) => item.system === 'email').value
                : '',
            line1: response?.address?.[0].line?.[0],
            line2: response.address?.[0].line?.[1],
            city: response.address?.[0].city,
            state: response.address?.[0].state,
            postalCode: response.address?.[0].postalCode,
            mentalHealthDiagnoses: extractLabels(
              response?.extension,
              '/intg/structure/extensions/mentalHealthDiagnoses'
            ),
            otherDiagnoses: extractLabels(response?.extension, '/intg/structure/extensions/otherDiagnoses'),
            race: response?.extension.find(
              (item: any) => item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race'
            )?.valueCodeableConcept?.coding?.[0]?.code,
          };
        });
        setSelectedRace(response?.extension.find(
          (item: any) => item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race'
        )?.valueCodeableConcept?.coding?.[0]?.display);
        setSelectedOtherOptions([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

        setSelectedOptions([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);

        setOtherDiagnosesList([...JSON.parse(response.extension.find((ext: any) => ext.url === "/intg/structure/extensions/otherDiagnoses")?.valueString || "[]")]);

        setDiagnosesList([...JSON.parse(response?.extension.find((ext: any) => ext.url === "/intg/structure/extensions/mentalHealthDiagnoses")?.valueString || "[]")]);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [medplum, id]);

  const extractLabels = (extensions: any[], url: string) => {
    const extension = extensions.find((ext) => ext.url === url);
    const valueString = extension ? JSON.parse(extension.valueString) : [];
    return valueString.map((diagnosis: { value: any }) => diagnosis.value).filter((value: any) => !!value);
  };

  const handleSearchChange = (query: string): any => {
    setSearchQuery(query);

    if (query.trim() !== '' && query?.length >= 3) {
      const filteredDiagnosesList = icdCodes.filter(
        (diagnoses) =>
          diagnoses.code?.toLowerCase()?.includes(query?.toLowerCase()) ||
          diagnoses.label?.toLowerCase()?.includes(query?.toLowerCase())
      );

      const updatedListWithValues = filteredDiagnosesList.map((item: ICDCodeProps) => {
        return {
          ...item,
          value: `${item.code} - ${item.label}`,
          label: `${item.code} - ${item.label}`
        };
      });

      if (selectedOptions?.length) {
        setDiagnosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
        setTempDignosesList([...new Set([...selectedOptions, ...updatedListWithValues])]);
      } else {
        setDiagnosesList([...updatedListWithValues]);
        setTempDignosesList([...updatedListWithValues]);
      }
    }
  };

  const handleSelectChangeMentalHealth = (values: any[]): any => {
    const selectedItems: any = [];
    tempDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          const shortLabel = item.label.split(' - ')[1] || item.label;
          selectedItems.push({
            ...item,
            label: shortLabel,
          });
        }
      });
    });
    setSelectedOptions([...selectedItems]);
  };

  const handleOtherSearchChange = (query: string): any => {
    setOtherSearchQuery(query);

    if (query.trim() !== '' && query?.length >= 3) {
      const filteredOtherDiagnosesList = (otherIcdCodes as ICDCodeProps[]).filter(
        (diagnoses) =>
          diagnoses.code?.toLowerCase()?.includes(query?.toLowerCase()) ||
          diagnoses.label?.toLowerCase()?.includes(query?.toLowerCase())
      );

      const updatedListWithValues = filteredOtherDiagnosesList.map((item: ICDCodeProps) => {
        return {
          ...item,
          value: `${item.code} - ${item.label}`,
          label: `${item.code} - ${item.label}`
        };
      });

      if (selectedOtherOptions?.length) {
        setOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
        setTempOtherDiagnosesList([...new Set([...selectedOtherOptions, ...updatedListWithValues])]);
      } else {
        setOtherDiagnosesList([...updatedListWithValues]);
        setTempOtherDiagnosesList([...updatedListWithValues]);
      }
    }
  };

  const handleOtherSelectChange = (values: any[]): any => {
    const selectedItems: any = [];
    tempOtherDiagnosesList.forEach((item) => {
      values.forEach((value: any) => {
        if (item.value === value && !selectedItems.some((selectedItem: any) => selectedItem.value === item.value)) {
          const shortLabel = item.label.split(' - ')[1] || item.label;
          selectedItems.push({
            ...item,
            label: shortLabel,
          });
        }
      });
    });
    setSelectedOtherOptions([...selectedItems]);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const regex = /^[0-9()\-\s]*$/;
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (name === 'mobile') {
      if (value === '' || regex.test(value)) {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === 'firstName' || name === 'lastName') {
      if (value === '' || nameRegex.test(value)) {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleGenderChange = (value: string | null) => {
    setFormData((prevData: any) => ({
      ...prevData,
      gender: value === null || !gendersList.includes(value) ? '' : value,
    }));
  };

  const parseDate = (dateString: string): Date => {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  };

  const formatDate = (date: Date): string => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  const handleSubmit = () => {
    const phoneRegex = /^(?:\d{10}|\(\d{3}\)\d{3}-\d{4}|\(\d{3}\) \d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;

    if (formData.mobile && !phoneRegex.test(formData.mobile)) {
      showNotification({ color: 'red', message: 'Invalid phone number format' });
      return;
    }

    patientDetails.telecom = [
      {
        system: 'email',
        value: formData.email,
      },
      {
        system: 'phone',
        value: formData.mobile,
      },
    ];
    patientDetails.address = [
      {
        line: [formData?.line1 || '', formData?.line2 || ''],
        city: formData?.city || '',
        state: formData?.state || '',
        postalCode: formData?.postalCode || '',
      },
    ];
    //check if name is updated
    let isNameUpdated = false;
    if (patientDetails.name[0].given[0] !== formData.firstName || patientDetails.name[0].family !== formData.lastName) {
      isNameUpdated = true;
    }
    patientDetails.name[0].given[0] = formData.firstName + ' ' + formData.lastName;
    patientDetails.name[0].family = "";
    patientDetails.birthDate = formData.birthDate;
    patientDetails.gender = formData.gender;
    patientDetails?.extension?.forEach((item: any) => {
      if (item.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race') {
        item.valueCodeableConcept = {
          coding: [
            {
              code: formData.race,
              display: selectedRace,
              system: 'http://hl7.org/fhir/us/core/CodeSystem/us-core-race',
            },
          ],
        };
      }
      if (item.url === '/intg/structure/extensions/mentalHealthDiagnoses') {
        item.valueString = JSON.stringify(selectedOptions);
      }
      if (item.url === '/intg/structure/extensions/otherDiagnoses') {
        item.valueString = JSON.stringify(selectedOtherOptions);
      }
    });

    const resource = cleanResource(patientDetails);
    const payload = {
      patientResource: resource,
      isNameUpdated: isNameUpdated,
    }
    updatePatientResource(payload, medplum)
      .then(() => {
        showNotification({ color: 'green', message: 'Success' });
        window.location.href = `/${resourceType}/${id}/details`;
      })
      .catch((err) => {
        showNotification({ color: 'red', message: 'Failed to updated patient resource.' });
      });
  };
  const ItemComponent = forwardRef<HTMLDivElement, any>(({ label, code, ...others }: any, ref) => {
    const labelParts = label.split(' - ');
    const displayLabel = labelParts.length > 1 ? labelParts[1] : label;
    return (
      <div ref={ref} {...others}>
        <Grid>
          <Grid.Col md={2} lg={2}>
            {code}
          </Grid.Col>
          <Grid.Col md={10} lg={10}>
            {displayLabel}
          </Grid.Col>
        </Grid>
      </div>
    );
  });
  return (
    <div>
      {dataLoaded && (
        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (handleSubmit) {
              handleSubmit();
            }
          }}
        >
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Name</Text>
            </Grid.Col>
            {/* Name */}
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.firstName || ''}
                name="firstName"
                placeholder="First Name"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.lastName || ''}
                name="lastName"
                placeholder="Last Name"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Date of Birth</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <DateInput
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date.getTime())) {
                    handleInputChange({ target: { name: 'birthDate', value: formatDate(date) } });
                  } else {
                    handleInputChange({ target: { name: 'birthDate', value: '' } });
                  }
                }}
                maxDate={new Date()}
                value={formData.birthDate ? parseDate(formData.birthDate) : null}
                placeholder="MM-DD-YYYY"
                valueFormat="MM-DD-YYYY"
              />
            </Grid.Col>
          </Grid>

          {/* MultiSelect for Mental Health Diagnosis */}
          <Grid sx={{ alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Mental Health Diagnosis</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <MultiSelect
                multiple
                mb="md"
                data={diagnosesList}
                itemComponent={ItemComponent}
                placeholder="Search and select options"
                defaultValue={formData.mentalHealthDiagnoses}
                searchable
                searchValue={searchQuery}
                onSearchChange={(value) => {
                  const specialCharPattern = /^[a-zA-Z0-9- .]*$/;
                  if (specialCharPattern.test(value)) {
                    handleSearchChange(value)
                  }
                }}
                onChange={handleSelectChangeMentalHealth}
              />
            </Grid.Col>
          </Grid>

          {/* MultiSelect for Other Diagnosis */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Other Diagnosis</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <MultiSelect
                multiple
                data={otherDiagnosesList}
                itemComponent={ItemComponent}
                searchable
                placeholder="Search and select options"
                id="otherDiagnosis"
                name="otherDiagnoses"
                defaultValue={formData?.otherDiagnoses || []}
                searchValue={otherSearchQuery}
                onSearchChange={(value) => {
                  const specialCharPattern = /^[a-zA-Z0-9- ]*$/;
                  if (specialCharPattern.test(value)) {
                    handleOtherSearchChange(value)
                  }
                }}
                onChange={handleOtherSelectChange}
              />
            </Grid.Col>
          </Grid>

          {/* Mobile */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Mobile</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <TextInput
                value={formData.mobile || ''}
                type="text"
                id="mobile"
                name="mobile"
                placeholder="Mobile Number"
                onChange={handleInputChange}
                maxLength={14}
              />
            </Grid.Col>
          </Grid>

          {/* Email */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Email</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <TextInput
                value={formData.email || ''}
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          {/* Gender */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Gender</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <Select
                data={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                  { value: 'non-binary', label: 'Non-binary' },
                  { value: 'non-disclose', label: 'Non-disclose' },
                  { value: 'other', label: 'Other' },
                  { value: 'transgender-female', label: 'Transgender-female' },
                  { value: 'transgender-male', label: 'Transgender-male' },
                  { value: '', label: 'None (Neutral)' }
                ]}
                value={
                  gendersList.includes(formData?.gender)
                    ? formData?.gender
                    : ''
                }
                placeholder="Select"
                name="gender"
                onChange={handleGenderChange}
              />
            </Grid.Col>
          </Grid>

          {/* Race dropdown */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Race</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={9}>
              <Select
                data={defaultRaceOptions}
                placeholder="Select"
                name="race"
                value={formData.race || ''}
                onChange={
                  (value) => {
                    const selectedOption = defaultRaceOptions.find((option) => option.value === value);
                    setSelectedRace(selectedOption?.label);
                    setFormData((prevData: any) => ({
                      ...prevData,
                      race: value,
                    }))
                  }
                }
              />
            </Grid.Col>
          </Grid>

          {/* Address */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}>
              <Text style={{ fontSize: '14px', color: '#272D41', fontWeight: '500' }}>Address</Text>
            </Grid.Col>

            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.line1 || ''}
                type="text"
                id="line1"
                name="line1"
                placeholder="Line 1"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.line2 || ''}
                type="text"
                id="line2"
                name="line2"
                placeholder="Line 2"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>

          {/* City, State, Postal Code */}
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}></Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.city || ''}
                type="text"
                id="city"
                name="city"
                placeholder="City"
                onChange={handleInputChange}
              />
            </Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.state || ''}
                type="text"
                id="state"
                name="state"
                placeholder="State"
                onChange={handleInputChange}
              />
            </Grid.Col>
          </Grid>
          <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
            <Grid.Col span={12} lg={3}></Grid.Col>
            <Grid.Col span={12} lg={4.5}>
              <TextInput
                value={formData.postalCode || ''}
                type="number"
                id="postalCode"
                name="postalCode"
                placeholder="Postal Code"
                onChange={handleInputChange}
                maxLength={6}
              />
            </Grid.Col>
          </Grid>

          {/* Buttons */}
          <Grid sx={{ justifyContent: 'flex-end' }}>
            <Grid.Col span={12} lg={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outline"
                type="button"
                onClick={() => navigate(`/${resourceType}/${id}/details`)}
                style={{ marginRight: '8px' }}
              >
                Cancel
              </Button>
              <Button type="submit" sx={{ marginTop: '0' }}>Update</Button>
            </Grid.Col>
          </Grid>
        </form>
      )}
    </div>
  );
};

export default PatientEditPage;

import { Paper, Grid, Text, Button, Table, Group, Tabs, Select, Box, Tooltip, Loader, TextInput } from '@mantine/core';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip as RechartsTooltip, Legend, Bar } from 'recharts';
import { IconSearch, IconInfoCircle, IconX, IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getMonthlyTokensCostMetrics, getRevenueCostData, getSubscriptionMetrics, getTotalRevenueMetrics } from '../utils/util';
import { AsyncAutocomplete, useMedplum } from '@medplum/react';
import Overview from './Overview';
import { HeaderSearchTypes, toKey, toOption, buildGraphQLQuery, SearchGraphQLResponse, getResourcesFromResponse } from '../../../react/src/AppShell/HeaderSearchInput';

interface PaymentStatusData {
    customer: string;
    email: string;
    subscriptionDateTime: string;
    subscriptionStatus: string;
    amount: number;
    canceledDate: string;
    paymentMode: string;
    plan: string;
    id: string;
    discount?: Discount;
}

interface Discount {
    name: string;
    percent_off: number;
}

interface RevenueData {
    month: string;
    revenue: number;
    refund: number;
}

interface SubscriptionDurationTabsProps {
    duration: string;
    handleTabChange: (value: string | null) => void;
}

interface RevenueCostData {
    email: string;
    practitioner: string;
    revenue: number;
    sessions: number;
    status: boolean;
    tokenCount: number;
    totalCost: number;
    id: string;
}

export interface MonthlyTokensCostData {
    tokens: number;
    cost: number;
}

const paymentStatusTableFields = ['name', 'email', 'dateTime', 'status', 'canceledDate', 'amount', 'paymentMode', 'plan'];
const RevenueCostTableFields = ['practitioner', 'email', 'revenue', 'tokenCount', 'totalCost', 'sessionCounts'];

const statusDisplayMap: { [key: string]: string } = {
    trialing: 'Trialing',
    active: 'Active',
    past_due: 'Past Due',
    canceled: 'Canceled',
    unpaid: 'Unpaid',
};

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

//Render the value for the Subscription Status  table
const renderValue = (data: PaymentStatusData, field: string) => {
    switch (field) {
        case 'name':
            return data?.customer || '-';
        case 'email':
            return data?.email || '-';
        case 'dateTime':
            return data?.subscriptionDateTime ? data.subscriptionDateTime : '-';
        case 'status':
            return data?.subscriptionStatus ? statusDisplayMap[data.subscriptionStatus] || capitalizeFirstLetter(data.subscriptionStatus) : '-';
        case 'canceledDate':
            return data?.canceledDate ? (isNaN(new Date(data.canceledDate).getTime()) ? '-' : new Date(data.canceledDate).toLocaleDateString()) : '-';
        case 'amount':
            const amountValue = data?.amount ? `$${data.amount}` : '0';
            const discountIndicator = data?.discount ? (
                <Tooltip
                    label={
                        <>
                            {`Name: ${data.discount.name}`}
                            <br />
                            {`Percentage Off: ${data.discount.percent_off}%`}
                        </>
                    }
                    withArrow
                >
                    <span className='discount'>D</span>
                </Tooltip>
            ) : null;
            return (
                <>
                    {amountValue} {discountIndicator}
                </>
            );
        case 'plan':
            return data?.plan || '-';
        case 'paymentMode':
            return data?.paymentMode ? capitalizeFirstLetter(data.paymentMode) : '-';
        default:
            return '-';
    }
};

//Subscription Status Table Headers
const TableHeaders = ({ paymentStatus }: { paymentStatus: string | null }) => (
    <thead>
        <tr>
            {paymentStatusTableFields.map((field, index) => (
                (field !== 'canceledDate' || paymentStatus === 'canceled') && (
                    (field !== 'amount' || paymentStatus !== 'canceled') && (
                        <th key={index}
                            style={{
                                paddingLeft: '8px',
                                ...((field === 'status') ? { width: '120px' } : {}),
                                ...((field === 'email') ? { width: '210px' } : {}),
                                ...((field === 'plan' || field === 'name') ? { width: '200px' } : {}),
                                ...((field === 'dateTime') ? { width: '225px', paddingRight: '0' } : {}),
                                ...((field === 'canceledDate') ? { width: '200px', paddingRight: '0' } : {}),
                                ...((field === 'paymentMode') ? { width: '170px' } : {}),
                            }}
                        >
                            <Group position="apart" noWrap>
                                <Text weight={500} size="sm">
                                    {field === 'name' ? 'Name' :
                                        field === 'email' ? 'Email' :
                                            field === 'dateTime' ? 'Subscription Date' :
                                                field === 'canceledDate' ? 'Canceled Date' :
                                                    field === 'status' ? 'Status' :
                                                        field === 'amount' ? 'Amount' :
                                                            field === 'paymentMode' ? 'Payment Mode' :
                                                                field === 'plan' ? 'Plan' :
                                                                    ''}
                                </Text>
                            </Group>
                        </th>
                    ))
            ))}
            <th></th>
        </tr>
    </thead>
);

const TableRows = ({ data, paymentStatus }: { data: PaymentStatusData[], paymentStatus: string | null }) => (
    <tbody className='payment-status-table-body'>
        {data.length > 0 ? data.map((resource, index) => (
            <tr key={index} style={{ border: '1px solid #ebeae9' }}>
                {paymentStatusTableFields.map((field, i) => (
                    (field !== 'canceledDate' || paymentStatus === 'canceled') && (
                        (field !== 'amount' || paymentStatus !== 'canceled') && (
                            <td key={`cell-${index}-${i}`}>
                                {renderValue(resource, field)}
                            </td>
                        ))
                ))}
            </tr>
        )) : <tr><td className='no-data-div' colSpan={paymentStatusTableFields.length + 1}>No data available</td></tr>}
    </tbody>
);

//Revenue & Cost by Practitioner Table Headers
const RevenueCostTableHeaders = () => (
    <thead>
        <tr>
            {RevenueCostTableFields.map((field, index) => (
                <th key={index}
                    style={{
                        paddingLeft: '8px',
                    }}
                >
                    <Group position="apart" noWrap>
                        <Text weight={500} size="sm">
                            {field === 'practitioner' ? 'Practitioner' :
                                field === 'email' ? 'Email' :
                                    field === 'revenue' ? 'Revenue' :
                                        field === 'tokenCount' ? 'Token Counts' :
                                            field === 'totalCost' ? 'Token Cost' :
                                                field === 'sessionCounts' ? 'Session Counts' :
                                                    ''}
                        </Text>
                    </Group>
                </th>
            ))}
            <th></th>
        </tr>
    </thead>
);

//Render the value for the Subscription Status  table
const renderRevenueCostTableValue = (data: RevenueCostData, field: string) => {
    switch (field) {
        case 'practitioner':
            return data?.practitioner || '-';
        case 'email':
            return data?.email || '-';
        case 'revenue':
            return data?.revenue ? `$${data.revenue}` : '0';
        case 'sessionCounts':
            return data?.sessions || '0';
        case 'status':
            return data?.status || '-';
        case 'tokenCount':
            return data?.tokenCount || '0';
        case 'totalCost':
            return data?.totalCost ? `$${data.totalCost.toFixed(5)}` : '0';
        default:
            return '-';
    }
};

function DashBoard() {
    const medplum = useMedplum();
    const [paymentStatusData, setPaymentStatusData] = useState<PaymentStatusData[]>([]);
    const [duration, setDuration] = useState<string>('week');
    const [revenueDuration, setRevenueDuration] = useState<string>('week');
    const [revenueData, setRevenueData] = useState<RevenueData[]>([]);
    const [paymentStatus, setPaymentStatus] = useState<string>('active');
    const [hasMoreNext, setHasMoreNext] = useState<boolean>(true);
    const [hasMorePrevious, setHasMorePrevious] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loadingNext, setLoadingNext] = useState<boolean>(false);
    const [loadingPrev, setLoadingPrev] = useState<boolean>(false);
    const rowsPerPage = 10;
    const revenueCostOffsetPerPage = 10;
    const [revenueChartLoader, setRevenueChartLoader] = useState<boolean>(true);
    const [subscriptionTableLoader, setSubscriptionTableLoader] = useState<boolean>(true);
    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [revenueCostLoadingPrev, setRevenueCostLoadingPrev] = useState<boolean>(false);
    const [revenueCostLoadingNext, setRevenueCostLoadingNext] = useState<boolean>(false);
    const [revenueCostData, setRevenueCostData] = useState<RevenueCostData[]>([]);
    const [revenueCostduration, setRevenueCostDuration] = useState<string>('week');
    const [revenueCostTableLoading, setRevenueCostTableLoading] = useState<boolean>(true);
    const skipRevenueCostEffect = useRef(false);
    const [revenueCostOffset, setRevenueCostOffset] = useState<number>(0);
    const [selectedRevenuCostPractitionerSearchId, setSelectedRevenuCostPractitionerSearchId] = useState<string | null>(null);

    const fetchPaymentStatusData = useCallback(async (page: number, itemsPerPage: number, startingAfter?: string, endingBefore?: string, searchTerm?: string) => {
        setSubscriptionTableLoader(true);
        try {
            const data = await getSubscriptionMetrics(medplum, duration, paymentStatus, itemsPerPage.toString(), startingAfter, endingBefore, searchTerm);
            setPaymentStatusData(data.subscriptions || []);
            page === 1 ? setHasMorePrevious(false) : setHasMorePrevious(true);
            endingBefore ? setHasMoreNext(true) : setHasMoreNext(data?.hasMore || false);
        } catch (error) {
            console.error('Error fetching payment status data:', error);
            setPaymentStatusData([]);
            setHasMoreNext(false);
            setHasMorePrevious(false);
        } finally {
            setLoadingNext(false);
            setLoadingPrev(false);
            setSubscriptionTableLoader(false);
        }
    }, [medplum, duration, paymentStatus]);

    const fetchRevenueCostData = useCallback(async (revenueCostCount: number, revenueCostOffset: number, revenueCostDuration: string, selectedRevenuCostPractitionerSearchId?: string) => {
        setRevenueCostTableLoading(true);
        try {
            const data = await getRevenueCostData(medplum, revenueCostCount, revenueCostOffset, revenueCostDuration, selectedRevenuCostPractitionerSearchId || '');
            setRevenueCostData(data || []);
        } catch (error) {
            console.error('Error getting token count and cost for admin analytics:', error);
        }
        finally {
            setRevenueCostTableLoading(false);
            setRevenueCostLoadingPrev(false);
            setRevenueCostLoadingNext(false);
        }
    }, [medplum]);

    //search practitioner logic in Subscription Status table
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            fetchPaymentStatusData(1, rowsPerPage);
        }
    };

    const handleSearch = () => {
        if (!isSearching) {
            setIsSearching(true);
            fetchPaymentStatusData(1, rowsPerPage, undefined, undefined, searchTerm).finally(() => {
                setIsSearching(false);
            });
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
        fetchPaymentStatusData(1, rowsPerPage);
    };
    //search practitioner logic end here 

    const fetchTotalRevenueMetrics = useCallback(async () => {
        setRevenueChartLoader(true);
        try {
            const response = await getTotalRevenueMetrics(medplum, revenueDuration);
            const transformedData: RevenueData[] = Object.keys(response).map(month => ({
                month,
                revenue: response[month].revenue,
                refund: response[month].refund
            }));
            setRevenueData(transformedData);
            // Calculate total revenue
            const total = transformedData.reduce((acc, data) => acc + data.revenue, 0);
            setTotalRevenue(total);

        } catch (error) {
            console.error('Error fetching total revenue metrics:', error);
        } finally {
            setRevenueChartLoader(false);
        }
    }, [medplum, revenueDuration]);

    useEffect(() => {
        fetchTotalRevenueMetrics();
    }, [revenueDuration]);

    useEffect(() => {
        fetchPaymentStatusData(currentPage, rowsPerPage, undefined, undefined, searchTerm);
    }, [duration, paymentStatus, fetchPaymentStatusData]);

    useEffect(() => {
        if (skipRevenueCostEffect.current) {
            skipRevenueCostEffect.current = false;
            return;
        }
        fetchRevenueCostData(10, revenueCostOffset, revenueCostduration, selectedRevenuCostPractitionerSearchId || '');
    }, [fetchRevenueCostData, revenueCostduration, revenueCostOffset, selectedRevenuCostPractitionerSearchId]);

    const handleTabChange = (value: string | null) => {
        if (value !== null) {
            setDuration(value);
            setCurrentPage(1);
        }
    };

    const handleRevenueTabChange = (value: string | null) => {
        if (value !== null) {
            setRevenueDuration(value);
        }
    };

    const handlePaymentStatusChange = (value: string | null) => {
        if (value !== null) {
            setPaymentStatus(value);
            setCurrentPage(1);
        }
    }

    const handlePageChange = (page: number, direction: 'next' | 'prev') => {
        if (direction === 'next') {
            setLoadingNext(true);
            const startingAfter = paymentStatusData[paymentStatusData.length - 1]?.id;
            fetchPaymentStatusData(page, rowsPerPage, startingAfter);
        } else {
            setLoadingPrev(true);
            const endingBefore = paymentStatusData[0]?.id;
            fetchPaymentStatusData(page, rowsPerPage, undefined, endingBefore);
        }
        setCurrentPage(page);
    };

    //Session and Revenue Metrics by Practitioner Table row with expanded monthly tokens cost table
    const RevenueCostTableRows = ({ data, revenueCostduration }: { data: RevenueCostData[], revenueCostduration: string }) => {
        const [expandedRow, setExpandedRow] = useState<number | null>(null);
        const [monthlyTokensCostData, setMonthlyTokensCostData] = useState<{ [key: number]: { [monthYear: string]: MonthlyTokensCostData } }>({});
        const [loadingRow, setLoadingRow] = useState<number | null>(null);

        const fetchMonthlyTokensCostMetricsData = useCallback(async (practitionerId: string, rowIndex: number) => {
            setLoadingRow(rowIndex);
            try {
                const data = await getMonthlyTokensCostMetrics(medplum, practitionerId);
                if (data) {
                    setMonthlyTokensCostData((prev) => ({
                        ...prev,
                        [rowIndex]: data,
                    }));
                }
            } catch (error) {
                console.error('Error getting monthly tokens cost data:', error);
            } finally {
                setLoadingRow(null);
            }
        }, [medplum]);

        const toggleRowExpansion = useCallback(async (index: number, practitionerId: string) => {
            if (loadingRow !== null) return;

            if (expandedRow === index) {
                setExpandedRow(null);
            } else {
                setExpandedRow(index);
                if (!monthlyTokensCostData[index]) {
                    await fetchMonthlyTokensCostMetricsData(practitionerId, index);
                }
            }
        }, [expandedRow, fetchMonthlyTokensCostMetricsData, monthlyTokensCostData, loadingRow]);

        return (
            <tbody className="payment-status-table-body">
                {data.length > 0 ? (
                    data.map((resource, index) => {
                        const isExpanded = expandedRow === index;
                        const monthlyData = monthlyTokensCostData[index] || [];

                        return (
                            <React.Fragment key={index}>
                                {/* Main Row */}
                                <tr
                                    className={isExpanded ? 'expanded-row' : ''}
                                    style={{ border: '1px solid #ebeae9', cursor: 'pointer' }}
                                    onClick={() => {
                                        if (!loadingRow && revenueCostduration === 'month') {
                                            toggleRowExpansion(index, resource.id);
                                        }
                                    }}
                                >
                                    {RevenueCostTableFields.map((field, i) => (
                                        <td key={`cell-${index}-${i}`}>
                                            {renderRevenueCostTableValue(resource, field)}
                                        </td>
                                    ))}
                                    {revenueCostduration === 'month' && (
                                        <td>
                                            {isExpanded ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
                                        </td>
                                    )}
                                </tr>

                                {/* Expanded Row */}
                                {revenueCostduration === 'month' && isExpanded && (
                                    <tr>
                                        <td colSpan={RevenueCostTableFields.length + 1} style={{ height: '200px' }}>
                                            {loadingRow === index ? (
                                                <div className="loader-div" style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Loader size={34} />
                                                </div>
                                            ) : (
                                                <Table className="ex-col-div">
                                                    <thead>
                                                        <tr>
                                                            <th>Month & Year</th>
                                                            <th>Tokens</th>
                                                            <th>Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(monthlyData).map(([monthYear, data], i) => (
                                                            <tr key={i}>
                                                                <td>{monthYear}</td>
                                                                <td>{data.tokens}</td>
                                                                <td>{data.cost === 0 ? 0 : `$${data.cost.toFixed(5)}`}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })
                ) : (
                    <tr>
                        <td className="no-data-div" colSpan={RevenueCostTableFields.length + 1}>
                            No data available
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };

    //Add this to render the revenue chart
    const renderRevenueChart = () => {
        if (revenueData.length === 0) {
            return <Text align="center" size={16} ml={10} pb={10}>No data available</Text>;
        }

        return (
            <BarChart width={800} height={400} data={revenueData} barSize={32}>
                <CartesianGrid opacity={0.3} stroke="#e0e0e0" vertical={false} />
                <YAxis
                    tickFormatter={(value) => `${value}`}
                    label={{ value: 'Amount ($)', angle: -90, position: 'insideLeft', textAnchor: 'middle', dx: -5, dy: -10 }}
                />
                <XAxis dataKey="month" />
                <RechartsTooltip
                    contentStyle={{ backgroundColor: 'black', color: 'white', borderRadius: '8px' }}
                    itemStyle={{ color: 'white' }}
                    cursor={{ fill: 'transparent' }}
                    formatter={(value: number, name: string) => {
                        const formattedValue = value === 0 ? '0' : `$${value.toFixed(2)}`;
                        const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
                        return [formattedValue, capitalizedName];
                    }}
                    labelFormatter={(label) => {
                        return revenueDuration === 'month' ? `${label}` : '';
                    }}
                />
                <Legend formatter={(value: string) => value.charAt(0).toUpperCase() + value.slice(1)} />
                <Bar dataKey="revenue" stackId="a" fill="#3276C9" />
                <Bar dataKey="refund" stackId="a" fill="#fba610" />
            </BarChart>
        );
    };

    //Add this to render the revenue tabs
    const renderRevenueTabs = () => (
        <Tabs value={revenueDuration} onTabChange={handleRevenueTabChange} className='filter-duration'>
            <Tabs.List className="tabs-list">
                <Tabs.Tab value="week" mb={0} className="tab" disabled={revenueChartLoader}>Last 7 days</Tabs.Tab>
                <Tabs.Tab value="month" mb={0} className="tab" disabled={revenueChartLoader}>Last 30 days</Tabs.Tab>
                <Tabs.Tab value="12months" mb={0} className="tab" disabled={revenueChartLoader}>Last 12 months</Tabs.Tab>
            </Tabs.List>
        </Tabs>
    );

    const SubscriptionDurationTabs: React.FC<SubscriptionDurationTabsProps> = ({ duration, handleTabChange }) => (
        <Grid.Col span={12} pr={3} className='sub-tab-div'>
            <Tabs value={duration} onTabChange={handleTabChange} className='filter-duration'>
                <Tabs.List className="tabs-list">
                    <Tabs.Tab value="week" mb={0} className="tab" disabled={revenueChartLoader}>Last 7 days</Tabs.Tab>
                    <Tabs.Tab value="month" mb={0} className="tab" disabled={revenueChartLoader}>Last 30 days</Tabs.Tab>
                    <Tabs.Tab value="12months" mb={0} className="tab" disabled={revenueChartLoader}>Last 12 months</Tabs.Tab>
                </Tabs.List>
            </Tabs>
        </Grid.Col>
    );

    //Revenue & Cost by Practitioner Tabs
    const renderRevenueCostTabs = () => (
        <Grid.Col span={12} pr={22} pt={15} pb={15} sx={{ display: 'Flex', justifyContent: 'end' }}>
            <Tooltip label='View practitioner metrics including revenue, token/costs, and session counts for the selected time period.' position="top" withArrow>
                <IconInfoCircle color='gray' size={18} style={{ margin: '10px' }} />
            </Tooltip>
            <Tabs value={revenueCostduration} className='filter-duration' onTabChange={handleRevenueCostTabChange}>
                <Tabs.List className="tabs-list">
                    <Tabs.Tab value="week" mb={0} className="tab" disabled={revenueChartLoader}>Last 7 days</Tabs.Tab>
                    <Tabs.Tab value="month" mb={0} className="tab" disabled={revenueChartLoader}>Last 30 days</Tabs.Tab>
                    <Tabs.Tab value="12months" mb={0} className="tab" disabled={revenueChartLoader}>Last 12 months</Tabs.Tab>
                </Tabs.List>
            </Tabs>
        </Grid.Col>
    );

    const handleRevenueCostTabChange = (value: string | null) => {
        if (value !== null) {
            setRevenueCostDuration(value);
            setRevenueCostOffset(0);
        }
    };

    // Add this function for practitioner loading search data
    const practitionerSearchloadData = useCallback(
        async (input: string, signal: AbortSignal): Promise<HeaderSearchTypes[]> => {
            const query = buildGraphQLQuery(input, 'practitioner', 20);
            const options = { signal };
            const response = (await medplum.graphql(query, undefined, undefined, options)) as SearchGraphQLResponse;
            return getResourcesFromResponse(response, input, 20);
        },
        [medplum]
    );

    // practitioner search for revenue and cost table
    const renderRevenueCostAsyncAutocomplete = (onChange: (item: HeaderSearchTypes[]) => void, disabled: boolean) => (
        <AsyncAutocomplete
            size="sm"
            radius="md"
            icon={<IconSearch size={20} />}
            placeholder={'Search Practitioner'}
            disabled={disabled}
            toKey={toKey}
            toOption={toOption}
            onChange={onChange}
            loadOptions={practitionerSearchloadData}
            maxSelectedValues={0}
            clearSearchOnChange
            clearable={true}
            mt={5}
            sx={{
                input: {
                    width: '200px',
                    paddingTop: '6px',
                    marginTop: '-3px',
                    transition: 'none',
                    '&:focus-within': {
                        width: '200px',
                    },
                },
            }}
        />
    );

    const handleRevenueCostPageChange = (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            setRevenueCostLoadingNext(true);
            setRevenueCostOffset(revenueCostOffset + revenueCostOffsetPerPage);
        } else {
            setRevenueCostLoadingPrev(true);
            setRevenueCostOffset(Math.max(revenueCostOffset - revenueCostOffsetPerPage, 0));
        }
    };

    const handleRevenueCostPractitionerSearch = useCallback(
        async (item: HeaderSearchTypes[]): Promise<void> => {
            const id = item.length > 0 ? item[0].id ?? null : null;
            setSelectedRevenuCostPractitionerSearchId(id);
            setRevenueCostOffset(0);
            skipRevenueCostEffect.current = true;
            fetchRevenueCostData(revenueCostOffsetPerPage, 0, revenueCostduration, id?.toString());
        },
        [fetchRevenueCostData, revenueCostduration, revenueCostOffsetPerPage, revenueCostOffset, selectedRevenuCostPractitionerSearchId]
    );

    return (
        <Box>
            {/* Overview section */}
            <Overview />

            {/* Revenue Chart */}
            <Paper m="lg" p="xs" className='paper-box'>
                <Grid className='grid-layout'>
                    <Grid.Col span={6}>
                        <Text align="left" size={18} fw={600} ml={10} pb={10}>Revenue </Text>
                    </Grid.Col>
                    <Grid.Col span={6} sx={{ textAlign: 'end' }}>
                        <Button className='download-btn'>
                            <img src="../../../../img/dashboard/download.svg" alt="Download" />
                            <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                        </Button>
                    </Grid.Col>
                </Grid>
                <Grid className='grid-style'>
                    <Grid.Col span={4} fw={500} className='revenue-total'>Total Revenue : <span>${totalRevenue.toFixed(2)}</span>
                    </Grid.Col>
                    <Grid.Col span={8} className='filter-div'>
                        <Grid align="center" ml={20}>
                            <Grid.Col span={12} pr={22}>
                                {renderRevenueTabs()}
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    <Grid.Col span={12} className='revenue-chart-div'>
                        {revenueChartLoader ? <div className='loader-div'><Loader size={34} /></div> : renderRevenueChart()}
                    </Grid.Col>
                </Grid>
            </Paper>

            {/* Subscription Status table*/}
            <Paper m="lg" p="xs" className='paper-box'>
                <Grid className='grid-layout'>
                    <Grid.Col span={6}>
                        <Text align="left" size={18} fw={600} ml={10} pb={10}>Subscription Status</Text>
                    </Grid.Col>
                    <Grid.Col span={6} className='end-align'>
                        <Button className='download-btn'>
                            <img src="../../../../img/dashboard/download.svg" alt="Download" />
                            <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                        </Button>
                    </Grid.Col>
                </Grid>
                <Grid className='sub-options-div'>
                    <Grid.Col span={3} className='end-align'>
                        <Group className='sub-search-icon'>
                            <TextInput
                                placeholder="Search Practitioner"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: '14.60rem' }}
                                disabled={isSearching || subscriptionTableLoader}
                                rightSection={
                                    <Group spacing={0}>
                                        {searchTerm && (
                                            <IconX
                                                stroke={2}
                                                size={16}
                                                onClick={clearSearch}
                                                style={{ cursor: 'pointer', marginRight: '8px' }}
                                            />
                                        )}
                                        <Button onClick={handleSearch} disabled={isSearching} className='sub-search-btn' compact>
                                            {isSearching ? <IconSearch stroke={2} size={16} color='#adb5bd' /> : <IconSearch stroke={2} color='#adb5bd' size={16} />}
                                        </Button>
                                    </Group>
                                }
                                styles={() => ({
                                    input: {
                                        borderColor: '#ced4da',
                                        '&:focus': {
                                            borderColor: '#228be6',
                                        },
                                    },
                                })}
                            />
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={3} className='filter-div' p={0}>
                        <Grid.Col span={12} sx={{ display: 'Flex', paddingTop: '10px' }}>
                            <Text size={14} fw={500} color='#344054' p={6}>Status</Text>
                            <Select
                                value={paymentStatus}
                                onChange={handlePaymentStatusChange}
                                disabled={subscriptionTableLoader}
                                data={[
                                    { value: 'trialing', label: 'Trialing' },
                                    { value: 'active', label: 'Active' },
                                    { value: 'canceled', label: 'Canceled' },
                                    { value: 'past_due', label: 'Past Due' },
                                    { value: 'unpaid', label: 'Unpaid' },
                                ]}
                                placeholder="Select status"
                                sx={{ width: 'auto' }}
                            />
                            {paymentStatus === 'canceled' && (
                                <Tooltip label='Displays "Cancelled" transactions, shown according to the Subscription Date from Stripe.' position="top" withArrow>
                                    <IconInfoCircle color='gray' size={18} style={{ margin: '10px' }} />
                                </Tooltip>
                            )}
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={6} className='filter-div'>
                        <Grid align="center">
                            <SubscriptionDurationTabs
                                duration={duration}
                                handleTabChange={handleTabChange}
                            />
                        </Grid>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
                        {subscriptionTableLoader ? (
                            <div className='loader-div'>
                                <Loader size={34} />
                            </div>
                        ) : (
                            <Table className='feedback_table'>
                                <TableHeaders paymentStatus={paymentStatus} />
                                <TableRows data={paymentStatusData} paymentStatus={paymentStatus} />
                            </Table>
                        )}
                    </Grid.Col>
                </Grid>
                <Grid justify="center" mt="md" mb={6}>
                    <Button
                        onClick={() => handlePageChange(Math.max(currentPage - 1, 1), 'prev')}
                        disabled={loadingPrev || loadingNext || !hasMorePrevious}
                        className='pagination-btn'
                    >
                        {loadingPrev ? (
                            <>
                                Previous...
                                <Loader size="xs" />
                            </>
                        ) : (
                            'Previous'
                        )}
                    </Button>
                    <Button
                        onClick={() => handlePageChange(currentPage + 1, 'next')}
                        disabled={!hasMoreNext || loadingNext || loadingPrev || subscriptionTableLoader}
                        className='pagination-btn'
                    >
                        {loadingNext ? (
                            <>
                                Next...
                                <Loader size="xs" />
                            </>
                        ) : (
                            'Next'
                        )}
                    </Button>
                </Grid>
            </Paper>
            {/* Revenue and Cost by Practitioner  */}
            <Paper m="lg" p="xs" className='paper-box'>
                <Grid className='grid-layout'>
                    <Grid.Col span={6}>
                        <Text align="left" size={18} fw={600} ml={10} pb={10}>Session and Revenue Metrics by Practitioner </Text>
                    </Grid.Col>
                    <Grid.Col span={6} sx={{ textAlign: 'end' }}>
                        <Button className='download-btn'>
                            <img src="../../../../img/dashboard/download.svg" alt="Download" />
                            <Text pl={5} size={14} color='#344054' fw={600}>Download Report</Text>
                        </Button>
                    </Grid.Col>
                </Grid>
                <Grid className='grid-style'>
                    <Grid.Col span={3}>
                        {renderRevenueCostAsyncAutocomplete(handleRevenueCostPractitionerSearch, revenueCostTableLoading)}
                    </Grid.Col>
                    <Grid.Col span={8} className='filter-div'>

                        <Grid align="center" ml={20}>
                            {renderRevenueCostTabs()}
                        </Grid>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col lg={12} sx={{ overflow: 'auto' }}>
                        {revenueCostTableLoading ? (
                            <div className='loader-div'><Loader size={34} /></div>
                        ) : (
                            <Table className='feedback_table'>
                                <RevenueCostTableHeaders />
                                <RevenueCostTableRows data={revenueCostData} revenueCostduration={revenueCostduration} />
                            </Table>
                        )}
                    </Grid.Col>
                </Grid>
                <Grid justify="center" mt="md" mb={6}>
                    <Button
                        onClick={() => handleRevenueCostPageChange('prev')}
                        disabled={revenueCostLoadingPrev || revenueCostOffset === 0 || revenueCostLoadingNext || revenueCostTableLoading}
                        className='pagination-btn'
                    >
                        {revenueCostLoadingPrev ? <Loader size="xs" /> : 'Previous'}
                    </Button>
                    <Button
                        onClick={() => handleRevenueCostPageChange('next')}
                        disabled={revenueCostLoadingPrev || revenueCostLoadingNext || revenueCostData.length < revenueCostOffsetPerPage || revenueCostTableLoading}
                        className='pagination-btn'
                    >
                        {revenueCostLoadingNext ? <Loader size="xs" /> : 'Next'}
                    </Button>
                </Grid>
            </Paper>
        </Box>
    );
}
export default DashBoard;

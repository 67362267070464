/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Avatar, Box, Button, createStyles, FileButton, Group, Header as MantineHeader, Menu, Stack, Text, UnstyledButton } from '@mantine/core';
import { getReferenceString, ProfileResource } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useMedplumContext } from '@medplum/react-hooks';
import { IconLogout, IconSettings, IconIndentIncrease, IconIndentDecrease, IconHeadset, IconMessage2Star } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { HumanNameDisplay } from '../HumanNameDisplay/HumanNameDisplay';
import { ResourceAvatar } from '../ResourceAvatar/ResourceAvatar';
import { HeaderSearchInput } from './HeaderSearchInput';
import { useAppContext } from '../../../app/src/AppProvider';
import { showNotification } from '@mantine/notifications';
import ConfirmBox from '../../../app/src/dashboard/ConfirmBox';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import CouponReferral from '../../../app/src/dashboard/CouponReferral';


const useStyles = createStyles((theme) => ({
  header: {
    boxShadow: `${theme.shadows.xs}`,
  },

  logoButton: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: '#fff',
      'svg': {
        stroke: '#000',
      }
    },
  },

  user: {
    padding: `${theme.spacing.xs}`,
    transition: 'background-color 100ms ease',

    '&:hover': {
      '& .mantine-bwc8t6': {
        color: '#000',
      }
    },
  },

  userName: {
    color: '#3176c9',
    fontWeight: 500,
    lineHeight: 1,
    marginRight: 3,

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    '& .mantine-bwc8t6': {
      color: '#000',
    }
  },
}));

export interface HeaderProps {
  pathname?: string;
  searchParams?: URLSearchParams;
  headerSearchDisabled?: boolean;
  logo: React.ReactNode;
  version?: string;
  navbarToggle: () => void;
}

export function Header(props: HeaderProps): JSX.Element {
  const context = useMedplumContext();
  const { medplum, profile } = context;
  const navigate = useNavigate();
  const { classes, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { isSessionOn, setRedirectionUrl, isSummaryDetailsUpdated, setIsSessionStop, isSessionState, setIsNavbarVisible, isNavbarVisible, setModifiedContent,
    customTextareaRef, setIsNoteModified, setIsRedirectAfterSavingSoapNote, setIsIntercomVisible, setStartNewSessionBtnClick, setAppointMentIdValue, setDocRefeId, setSaveUnKnownSessionWhileRedirection, isSaveTranscripts
  } = useAppContext();
  const [logoUrl, setlogoUrl] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [baseUrl, setBaseUrl] = useState('');
  const [practiceName, setPracticeName] = useState<string>();
  const [collapseIcon, setCollapseIcon] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [shouldAccountSettingNavigate, setShouldAccountSettingNavigate] = useState(false);
  const [knownledgeCenterPageClick, setKnownledgeCenterPageClick] = useState(false);
  const currentUrl = window.location.pathname;
  const targetUrl = currentUrl === '/session';
  const redirectionPath = `/${getReferenceString(profile as ProfileResource)}/details`;
  const [isSignOutClick, setIsSignOutClick] = useState(false);
  const [isCouponReferralPopupOpen, setIsCouponReferralPopupOpen] = useState(false);
  const [navigateSession, setNavigateSession] = useState(false);

  useEffect(() => {
    profile?.extension?.forEach((ext) => {
      if (ext.url === '/pmhs/StructureDefinition/practitioner-logo') {
        if (ext.valueAttachment?.data) {
          setlogoUrl(`data:${ext.valueAttachment?.contentType};base64,${ext.valueAttachment?.data}`);
        }
      } else if (ext.url === '/pmhs/StructureDefinition/practice-name') {
        setPracticeName(ext.valueString);
      }
    });
    const url = window.location.host;
    setBaseUrl(url === 'localhost:3000' ? 'http://localhost:8103' : 'https://pmhscribe.com:8103');

    // Collapse the navbar initially for mobile view
    if (window.matchMedia('(max-width: 768px)').matches) {
      setCollapseIcon(false);
      setIsNavbarVisible(false);
      props.navbarToggle();
    }

  }, [profile]);

  //updating the logo
  const handleLogoUpdate = async (event: any) => {
    const token = await medplum.getAccessToken();
    const file = event?.[0];
    setFiles([file]);

    const formData = new FormData();
    formData.append('file', file);

    const url = `${baseUrl}/intg/update-practice-logo?practitionerId=${profile?.id}`;
    const headers = {
      Authorization: 'Bearer ' + token,
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: formData
      });

      const data = await response.json();
      showNotification({ color: 'green', message: data?.message });
      // If you need to do something with the response data, do it here
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (window.matchMedia('(max-width: 600px)').matches) {
      if (isNavbarVisible === false) {
        setCollapseIcon(false);
      }
    }
  }, [isNavbarVisible]);

  const collapseNavbar = (event: string) => {
    if (event === 'collapse') {
      setCollapseIcon(false);
    } else {
      setCollapseIcon(true);
    }
    props.navbarToggle();
  }

  const handleConfirm = () => {
    setIsConfirmOpen(false);
    window.location.href = '/session';
  }

  const handleStartNewSession = () => {
    setStartNewSessionBtnClick(true);
    setIsNavbarVisible(false);

    // Reset values only if no session is active
    if (!isSessionOn) {
      setDocRefeId('');
      setAppointMentIdValue('');
    }

    if (window.location.href.includes('/Appointment') && (isSaveTranscripts || isSessionOn)) {
      //save the unknown session transcript before redirection
      setSaveUnKnownSessionWhileRedirection(true);
      setNavigateSession(true);
    } else if (window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
      setShouldNavigate(true);
    } else if (!customTextareaRef.current?.getEditorValue() && window.location.href.includes('/Appointment')) {
      setIsRedirectAfterSavingSoapNote(true);
      setShouldNavigate(true);
    }
    else {
      setModifiedContent('');
      setIsNoteModified(false);
      if (!(isSessionOn || isSummaryDetailsUpdated) || (isSessionState === 'paused')) {
        setRedirectionUrl(targetUrl);
        navigate('/session');
      } else {
        setIsSessionStop(true);
      }
    }
  };

  useEffect(() => {
    if (shouldNavigate) {
      setRedirectionUrl(targetUrl);
      navigate('/session');
      setShouldNavigate(false);
    } else if (redirectionPath && shouldAccountSettingNavigate) {
      setRedirectionUrl(false);
      navigate(redirectionPath);
      setShouldAccountSettingNavigate(false);
    }
    else if (knownledgeCenterPageClick) {
      navigate('/knowledge-center');
      setKnownledgeCenterPageClick(false);
    }
    else if (isSignOutClick) {
      medplum.signOut();
      navigate('/signin');
      setIsSignOutClick(false);
    }
    else if (navigateSession) {
      navigate('/session');
      setNavigateSession(false);
    }
  }, [navigateSession, shouldNavigate, targetUrl, navigate, setRedirectionUrl, redirectionPath, shouldAccountSettingNavigate, knownledgeCenterPageClick, isSignOutClick]);

  const handleSignOutClick = async () => {
    setIsLoading(true);
    const redirectionPath = `/signin`;

    if (window.location.href.includes('/Appointment') && (isSaveTranscripts || isSessionOn)) {
      //save the unknown session transcript before redirection
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSaveUnKnownSessionWhileRedirection(true);
      setIsSignOutClick(true);

    } else if (window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
      setIsSignOutClick(true);

    } else if (!customTextareaRef.current?.getEditorValue() && window.location.href.includes('/Appointment')) {
      setIsRedirectAfterSavingSoapNote(true);
      setIsSignOutClick(true);

    } else if (isSessionOn || isSummaryDetailsUpdated) {
      if (isSessionState !== "" && isSessionState !== 'paused') {
        setRedirectionUrl(redirectionPath);
      }
      else {
        await medplum.signOut();
        navigate(redirectionPath);
      }

    } else {
      await medplum.signOut();
      navigate(redirectionPath);
    }
    setIsLoading(false);
  };

  const handleAccountSettingsClick = () => {
    if (window.location.href.includes('/Appointment') && (isSaveTranscripts || isSessionOn)) {
      //save the unknown session transcript before redirection
      setSaveUnKnownSessionWhileRedirection(true);
      setShouldAccountSettingNavigate(true);
    } else if (window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
      setShouldAccountSettingNavigate(true);
    } else if (!customTextareaRef.current?.getEditorValue() && window.location.href.includes('/Appointment')) {
      setIsRedirectAfterSavingSoapNote(true);
      setShouldAccountSettingNavigate(true);
    }
    else if (!(isSessionOn || isSummaryDetailsUpdated)) {
      if (isSessionState === 'paused') {
        setRedirectionUrl(redirectionPath);
      } else {
        setRedirectionUrl(false);
        navigate(redirectionPath);
      }
    } else {
      setIsSessionStop(true);
      setRedirectionUrl(redirectionPath);
    }
  };

  const handleResourcesClick = () => {
    if (window.location.href.includes('/Appointment') && (isSaveTranscripts || isSessionOn)) {
      //save the unknown session transcript before redirection
      setSaveUnKnownSessionWhileRedirection(true);
      setKnownledgeCenterPageClick(true);
    } else if (window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteModified(true);
      setKnownledgeCenterPageClick(true);
    }
    else if (!customTextareaRef.current?.getEditorValue() && window.location.href.includes('/Appointment')) {
      setIsRedirectAfterSavingSoapNote(true);
      setKnownledgeCenterPageClick(true);
    }
    else if (!(isSessionOn || isSummaryDetailsUpdated)) {
      if (isSessionState === 'paused') {
        setRedirectionUrl('/knowledge-center');
      } else {
        setRedirectionUrl(false);
        navigate('/knowledge-center');
      }
    } else {
      setRedirectionUrl('/knowledge-center');
    }
  };

  const setIntercomVisibility = () => {
    setIsIntercomVisible(true);
  };

  return (
    <MantineHeader className={`header ${classes.header}`} height={70} p={8} style={{ zIndex: 101, justifyContent: 'space-between' }}>
      <Group position="apart" className='main-header'>
        <Group sx={{ alignItems: 'end' }} className='logo-header'>
          <Box mx="20px"
            onClick={() => {
              const isSessionPage = window.location.pathname === '/session';
              isSessionPage ? window.location.reload() : navigate('/session');
            }}
            sx={{ cursor: 'pointer' }}
            className='mobile-logo-style'
          >
            {props.logo}
          </Box>
          {collapseIcon ? <IconIndentIncrease onClick={() => collapseNavbar('collapse')} style={{ cursor: 'pointer' }} /> : <IconIndentDecrease onClick={() => collapseNavbar('expande')} style={{ cursor: 'pointer' }} />}
        </Group>
        <Group className='header-search'>
          {!props.headerSearchDisabled && (
            <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} searchType={'patient'} />
          )}
        </Group>
        <Group className='header-menu-mob'>
          <div className='logout-mo-icon'>
            <IconLogout size={22} stroke={1.5} className='mo-logout'
              onClick={() => setIsLogoutConfirmOpen(true)}
            />
            <Button
              variant="light"
              sx={{
                backgroundColor: '#fba610', color: '#fff',
                '&:hover': {
                  background: '#fba610',
                }
              }}
              onClick={handleStartNewSession}
            >
              Start New Session
            </Button>
          </div>
          {isConfirmOpen && (
            <ConfirmBox
              isOpen={isConfirmOpen}
              onClose={() => setIsConfirmOpen(false)}
              onConfirm={() => handleConfirm()}
              content={
                'Please note that confirming this action will completely discard the unsaved changes. Would you like to proceed?'
              }
            />
          )}

        </Group>
        <Group className='header-menu-des'>
          <Button
            variant="light"
            sx={{
              backgroundColor: '#fba610', color: '#fff',
              '&:hover': {
                background: '#fba610',
              }
            }}
            onClick={handleStartNewSession}
          >
            Start New Session
          </Button>
          <Menu
            width={260}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            opened={userMenuOpened}
            onClose={() => setUserMenuOpened(false)}
          >
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                onClick={() => setUserMenuOpened((o) => !o)}
              >
                <Group spacing={7}>
                  <div style={{ textAlign: 'end' }}>
                    <Text size="sm">
                      {' '}
                      Welcome back, <HumanNameDisplay value={context.profile?.name?.[0] as HumanName} />!{' '}
                    </Text>
                    {!medplum.isSuperAdmin() && medplum.getProjectMembership()?.admin && (
                      <Text color="#3176C9" size="xs">
                        {practiceName}
                      </Text>
                    )}
                  </div>
                  {logoUrl ? (
                    <Avatar radius="xl" src={logoUrl} />
                  ) : (
                    files.length > 0 ? (
                      <Avatar radius="xl" src={URL.createObjectURL(files[0])} />
                    ) : (
                      <ResourceAvatar value={profile} radius={0} size={32} color="#3176C9" />
                    )
                  )}
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Stack align="center" p="xl">
                {logoUrl ? (<Avatar radius="xl" size={70} src={logoUrl} />
                ) : (
                  <FileButton onChange={handleLogoUpdate} accept="image/png,image/jpeg" multiple>
                    {(props) =>
                      files.length > 0 ? (
                        <Avatar radius="xl" src={URL.createObjectURL(files[0])} />
                      ) : (<ResourceAvatar {...props} size="xl" sx={{ cursor: 'pointer' }} radius={100} value={context.profile} color="#3176C9" />)}
                  </FileButton>
                )}
                <HumanNameDisplay value={context.profile?.name?.[0] as HumanName} />
                <Text color="dimmed" size="xs">
                  {medplum.getActiveLogin()?.project.display}
                </Text>
              </Stack>
              <Menu.Divider />
              <Menu.Item
                icon={<IconSettings size={14} stroke={1.5} />}
                onClick={handleAccountSettingsClick}
              >
                Account Settings
              </Menu.Item>
              {/* <Menu.Item
                icon={<IconCoinMonero size={14} stroke={1.5} />}
                onClick={() => window.open("https://pmhscribe.getrewardful.com/signup", "_blank")}
              >
                Become an Ambassador
              </Menu.Item> */}
              <Menu.Item
                icon={<img src="../../../../img/icons/resource.svg" />}
                onClick={handleResourcesClick}
              >
                Knowledge Center
              </Menu.Item>
              <Menu.Item
                icon={<IconHeadset size={14} stroke={1.5} />}
                onClick={setIntercomVisibility}
              >
                Help Desk Support
              </Menu.Item>

              <Menu.Item
                icon={<IconMessage2Star size={14} stroke={1.5} />}
                onClick={() => window.open("https://form.jotform.com/erxai/Testimonial", "_blank")}
              >
                Leave a Testimonial
              </Menu.Item>
              {/* {(paymentStatus || medplum.isSuperAdmin()) && (
                <Menu.Item
                  icon={<IconCoins size={14} stroke={1.5} />}
                  onClick={() => setIsCouponReferralPopupOpen(true)}
                >
                  Referral Rewards
                </Menu.Item>
              )} */}
              <Menu.Item
                icon={<IconLogout size={14} stroke={1.5} />}
                onClick={handleSignOutClick}
              >
                Sign Out
              </Menu.Item>
              <Text size="xs" color="dimmed" align="center">
                {props.version}
              </Text>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>

      <ConfirmationModal
        isOpen={isLogoutConfirmOpen}
        onClose={() => setIsLogoutConfirmOpen(false)}
        onConfirm={handleSignOutClick}
        content="Are you sure you want to logout?"
        confirmButtonText="Logout"
        cancelButtonText="Cancel"
        isLoading={isLoading}
      />

      {isCouponReferralPopupOpen && (
        <CouponReferral
          opened={isCouponReferralPopupOpen}
          onClose={() => setIsCouponReferralPopupOpen(false)}
          onSubmit={(email) => {
            console.log('Submitted referral email:', email);
          }}
        />
      )}

    </MantineHeader>
  );
};

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-debugger */
import { Anchor, Checkbox, Group, Input, MultiSelect, NativeSelect, PasswordInput, Radio, Stack, Stepper, Textarea, TextInput } from '@mantine/core';
import {
  capitalize,
  evalFhirPathTyped,
  formatCoding,
  getElementDefinition,
  getTypedPropertyValue,
  stringify,
  TypedValue,
} from '@medplum/core';
import {
  QuestionnaireItem,
  QuestionnaireItemAnswerOption,
  QuestionnaireItemInitial,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from '@medplum/fhirtypes';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  CheckboxFormSection,
  DateTimeInput,
  FormSection,
  getNewMultiSelectValues,
  QuantityInput,
  QuestionnaireItemType,
  ReferenceInput,
  ResourcePropertyDisplay,
} from '@medplum/react';
import { AttachmentInput } from './AttachmentInput';
import { formatValue } from '../utils';
import { getStripeSession } from '../utils/util';

interface QuestionnaireFormItemArrayProps {
  items: QuestionnaireItem[];
  answers: Record<string, QuestionnaireResponseItemAnswer>;
  renderPages?: boolean;
  activePage?: number;
  updateNumberOfPages?: (newValue: number) => void;
  onChange: (newResponseItems: QuestionnaireResponseItem[]) => void;
}

const urlParams = new URLSearchParams(window.location.search);
const firstNameParam = urlParams.get('firstName');
const lastNameParam = urlParams.get('lastName');

export function QuestionnaireFormItemArray(props: QuestionnaireFormItemArrayProps): JSX.Element {
  const [responseItems, setResponseItems] = useState<QuestionnaireResponseItem[]>(
    buildInitialResponseItems(props.items)
  );

  function setResponseItem(responseId: string, newResponseItem: QuestionnaireResponseItem): void {
    const itemExists = responseItems.some((r) => r.id === responseId);
    let newResponseItems;
    if (itemExists) {
      newResponseItems = responseItems.map((r) => (r.id === responseId ? newResponseItem : r));
    } else {
      newResponseItems = [...responseItems, newResponseItem];
    }
    setResponseItems(newResponseItems);
    props.onChange(newResponseItems);
  }

  const questionForm = props.items.map((item, index) => {
    if (props.renderPages && isQuestionEnabled(item, props.answers)) {
      return (
        <Stepper.Step key={item.linkId}>
          <QuestionnaireFormArrayContent
            key={`${item.linkId}-${index}`}
            item={item}
            index={index}
            answers={props.answers}
            responseItems={responseItems}
            setResponseItem={setResponseItem}
          />
        </Stepper.Step>
      );
    } else if (isQuestionEnabled(item, props.answers)) {
      return (
        <QuestionnaireFormArrayContent
          key={`${item.linkId}-${index}`}
          item={item}
          index={index}
          answers={props.answers}
          responseItems={responseItems}
          setResponseItem={setResponseItem}
        />
      );
    }
    return null;
  });

  // Calculate the number of enabled pages
  const enabledPagesCount = props.items.reduce((count, item) => {
    if (isQuestionEnabled(item, props.answers)) {
      return count + 1;
    }
    return count;
  }, 0);

  if (props.updateNumberOfPages) {
    props.updateNumberOfPages(enabledPagesCount);
  }

  if (props.renderPages) {
    return (
      <Stepper active={props.activePage ?? 0} allowNextStepsSelect={false} className="quiz-stepper">
        {questionForm}
      </Stepper>
    );
  }
  return <Stack>{questionForm}</Stack>;
}

interface QuestionnaireFormArrayContentProps {
  item: QuestionnaireItem;
  index: number;
  answers: Record<string, QuestionnaireResponseItemAnswer>;
  responseItems: QuestionnaireResponseItem[];
  setResponseItem: (responseId: string, newResponseItem: QuestionnaireResponseItem) => void;
}

function QuestionnaireFormArrayContent(props: QuestionnaireFormArrayContentProps): JSX.Element | null {
  if (!isQuestionEnabled(props.item, props.answers)) {
    return null;
  }
  if (props.item.type === QuestionnaireItemType.display) {
    return <p key={props.item.linkId}>{props.item.text}</p>;
  }
  if (props.item.type === QuestionnaireItemType.group) {
    return (
      <QuestionnaireRepeatWrapper
        key={props.item.linkId}
        item={props.item}
        answers={props.answers}
        responseItems={props.responseItems}
        onChange={(newResponseItem) => props.setResponseItem(newResponseItem.id as string, newResponseItem)}
      />
    );
  }
  return (
    <FormSection key={props.item.linkId} htmlFor={props.item.linkId} title={props.item.text ?? ''} withAsterisk={props.item?.required || false}>
      <QuestionnaireRepeatWrapper
        item={props.item}
        answers={props.answers}
        responseItems={props.responseItems}
        onChange={(newResponseItem) => props.setResponseItem(newResponseItem.id as string, newResponseItem)}
      />
    </FormSection>
  );
}

export interface QuestionnaireRepeatWrapperProps {
  item: QuestionnaireItem;
  answers: Record<string, QuestionnaireResponseItemAnswer>;
  responseItems: QuestionnaireResponseItem[];
  onChange: (newResponseItem: QuestionnaireResponseItem, index?: number) => void;
}

export function QuestionnaireRepeatWrapper(props: QuestionnaireRepeatWrapperProps): JSX.Element {
  const item = props.item;
  function onChangeItem(newResponseItems: QuestionnaireResponseItem[], number?: number): void {
    const index = number ?? 0;
    const responses = props.responseItems.filter((r) => r.linkId === item.linkId);
    props.onChange({
      id: getResponseId(responses, index),
      linkId: item.linkId,
      text: item.text,
      item: newResponseItems,
    });
  }
  if (item.type === QuestionnaireItemType.group) {
    return (
      <RepeatableGroup
        key={props.item.linkId}
        text={item.text ?? ''}
        item={item ?? []}
        answers={props.answers}
        onChange={onChangeItem}
      />
    );
  }
  return (
    <RepeatableItem item={props.item} key={props.item.linkId}>
      {({ index }: { index: number }) => <QuestionnaireFormItem {...props} index={index} allResponses={[]} />}
    </RepeatableItem>
  );
}

export interface QuestionnaireFormItemProps {
  item: any;
  index: number;
  allResponses: QuestionnaireResponseItem[];
  answers: Record<string, QuestionnaireResponseItemAnswer>;
  responseItems?: QuestionnaireResponseItem[];
  onChange: (newResponseItem: QuestionnaireResponseItem) => void;
}

const validatePassword = (password: string) => {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength || !hasUppercase || !hasNumber || !hasSpecialChar) {
    return 'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.';
  }
  return '';
};

export function QuestionnaireFormItem(props: QuestionnaireFormItemProps): JSX.Element | null {
  const item = props.item;
  const index = props.index;
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  function onChangeAnswer(newResponseAnswer: QuestionnaireResponseItemAnswer, repeatedIndex?: number): void {
    const number = repeatedIndex ?? 0;
    const responses = props.responseItems?.filter((r) => r.linkId === item.linkId) ?? [];
    props.onChange({
      id: responses[0]?.id,
      linkId: item.linkId,
      text: item.text,
      answer: updateAnswerArray(responses[0]?.answer ?? [], number, newResponseAnswer),
    });
  }

  const [storedData, setStoredData] = useState<any>(null);
  const storedDataString = sessionStorage.getItem('response_data');
  useEffect(() => {
    if (storedDataString) {
      try {
        const parsedData = JSON.parse(storedDataString);
        setStoredData(parsedData);
      } catch (error) {
        console.error('Error parsing stored data:', error);
      }
    }
  }, []);

  const type = item.type as QuestionnaireItemType;
  if (!type) {
    return null;
  }

  const name = item.linkId;
  if (!name) {
    return null;
  }

  const formatAndValidateNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength <= 3) return phoneNumber;
    if (phoneNumberLength <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    if (phoneNumberLength <= 10) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInput = (e: React.FormEvent<HTMLInputElement>, field: string) => {
    const target = e.currentTarget;
    let inputValue = target.value;

    if (field === 'License Number') {
      inputValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '');
      target.value = inputValue;
    }

    //Format npi1 and npi2 fields
    if (field === 'NPI 1' || field === 'NPI 2') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      target.value = inputValue;

      if (inputValue.length > 10) {
        target.value = inputValue.slice(0, 10);
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      } else if (inputValue.length < 10) {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: `${field} should be 10 digits long.` }));
      } else {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      }
      setValues((prevValues: any) => ({ ...prevValues, [field]: target.value }));
      return;
    }

    // Format and validate phone numbers, fax numbers and personal cell numbers
    if (field === 'Practice Phone' || field === 'Practice Fax' || field === 'Personal Cell') {
      inputValue = formatAndValidateNumber(inputValue);
      target.value = inputValue;

      // Validation for number of digits (should be 10 digits)
      if (inputValue.replace(/-/g, '').length !== 10) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]: `${field} should be 10 digits long.`,
        }));
      } else {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      }
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
    }

    else if (field === 'Practice Email\n' || field === 'Personal Email') {
      // Regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(inputValue)) {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: `${field} is not valid.` }));
      } else {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
      }
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
    } else {
      setValues((prevValues: any) => ({ ...prevValues, [field]: inputValue }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    }
  };

  const handleCheckValidity = (e: any, field: string) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    const formattedValue = formatValue(e.target.value);

    if (formattedValue.length === 10 || formattedValue.length === 0) {
      setValues((prevValues: any) => ({ ...prevValues, [field]: formattedValue }));
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: '' }));
    } else {
      setValues((prevValues: any) => ({ ...prevValues, [field]: formattedValue }));
    }
  };

  const initial = item.initial && item.initial.length > 0 ? item.initial[0] : undefined;
  const searchParams = new URLSearchParams(window.location.search);
  const sessionId = searchParams.get('sessionId');
  const [mail, setMail] = useState('');
  let foundPostalCode = '';
  for (let i = 0; i < (storedData?.addresses?.length || 0); i++) {
    if (storedData.addresses[i]?.teleNumber) {
      foundPostalCode = storedData.addresses[i].teleNumber || storedData.addresses[i].telephone_number;
      break;
    }
  }

  const getDefaultValue = () => {
    const { linkId } = props.item;

    if (props.answers?.[linkId]?.valueString) {
      return props.answers[linkId].valueString;
    }

    switch (linkId) {
      case 'Q1':
        return firstNameParam || storedData?.basic?.firstName || storedData?.basic?.aoFirstName || storedData?.basic?.first_name || storedData?.basic?.authorized_official_first_name || '';
      case 'Q2':
        return lastNameParam || storedData?.basic?.lastName || storedData?.basic?.aoLastName || storedData?.basic?.last_name || storedData?.basic?.authorized_official_last_name || '';
      case 'Q6':
        return storedData?.basic?.name || storedData?.basic?.first_name || '';
      case 'Q7':
        return storedData?.addresses?.[0]?.addressLine1 || storedData?.addresses?.[0].address_1 || '';
      case 'Q8':
        return storedData?.addresses?.[0]?.addressLine2 || storedData?.addresses?.[0].address_2 || '';
      case 'Q9':
        return storedData?.addresses?.[0]?.city || '';
      case 'Q10':
        return storedData?.addresses?.[0]?.state || '';
      case 'Q11':
        return storedData?.addresses?.[0]?.postalCode || storedData?.addresses?.[0]?.postal_code || '';
      case 'Q19':
        return foundPostalCode ? foundPostalCode : storedData?.practiceLocations?.teleNumber || '';
      case 'Q15':
        return storedData?.number || '';
      case 'Q12':
        return storedData?.practiceLocations?.[0]?.teleNumber || '';
      case 'Q20':
        if (sessionId) {
          const payload = { sessionId };
          getStripeSession(payload).then((data) => {
            setMail(data.email);
          });
        }
        return mail || '';
      case 'Q22':
        return item?.answer ? item.answer?.[0].valueString : '';
      default:
        return initial?.valueString || '';
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.currentTarget.value;
    const errorMessage = validatePassword(password);

    setValues((prevValues: any) => ({ ...prevValues, [props.item.text]: password }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [props.item.text]: errorMessage }));

    onChangeAnswer({ valueString: password }, props.index);
  };

  switch (type) {
    case QuestionnaireItemType.display:
      return <p key={props.item.linkId}>{props.item.text}</p>;
    case QuestionnaireItemType.boolean:
      return (
        <CheckboxFormSection key={props.item.linkId} title={props.item.text} htmlFor={props.item.linkId}>
          <Checkbox
            id={props.item.linkId}
            name={props.item.linkId}
            defaultChecked={initial?.valueBoolean}
            onChange={(e) => onChangeAnswer({ valueBoolean: e.currentTarget.checked }, index)}
          />
        </CheckboxFormSection>
      );
    case QuestionnaireItemType.decimal:
      return (
        <TextInput
          type="number"
          step="any"
          id={name}
          name={name}
          required={item.required}
          defaultValue={initial?.valueDecimal}
          onChange={(e) => onChangeAnswer({ valueDecimal: e.currentTarget.valueAsNumber }, index)}
        />
      );
    case QuestionnaireItemType.integer:
      return (
        <Input
          type="number"
          step={1}
          id={name}
          name={name}
          required={item.required}
          defaultValue={initial?.valueInteger}
          onChange={(e) => onChangeAnswer({ valueInteger: e.currentTarget.valueAsNumber }, index)}
        />
      );
    case QuestionnaireItemType.date:
      return (
        <TextInput
          type="date"
          id={name}
          name={name}
          required={item.required}
          defaultValue={initial?.valueDate}
          onChange={(e) => onChangeAnswer({ valueDate: e.currentTarget.value }, index)}
        />
      );
    case QuestionnaireItemType.dateTime:
      return (
        <DateTimeInput
          name={name}
          required={item.required}
          defaultValue={initial?.valueDateTime}
          onChange={(newValue: string) => onChangeAnswer({ valueDateTime: newValue }, index)}
        />
      );
    case QuestionnaireItemType.time:
      return (
        <TextInput
          type="time"
          id={name}
          name={name}
          required={item.required}
          defaultValue={initial?.valueTime}
          onChange={(e) => onChangeAnswer({ valueTime: e.currentTarget.value }, index)}
        />
      );
    case QuestionnaireItemType.string:
    case QuestionnaireItemType.url:
      return (
        props.item.linkId === 'Q23' && props.item.text === 'Password' ?
          <>
            <PasswordInput
              placeholder="Password"
              required={props.item.required}
              defaultValue={values[props.item.text] || props.answers?.[props.item.linkId]?.valueString || ''}
              onChange={handlePasswordChange}
            />
            {errors[props.item.text] && <span className="error">{errors[props.item.text]}</span>}
          </>
          :
          (
            <>
              <TextInput
                id={name}
                name={name}
                value={item.validation && values[props.item.text]}
                required={item.required}
                defaultValue={values[props.item.text] || getDefaultValue()}
                readOnly={(props.item.linkId === 'Q22' && item?.answer) ||
                  (props.item.linkId === 'Q15' && (storedData?.number || ''))
                }

                onChange={(e) => {
                  const newValue = e.currentTarget.value;
                  setValues((prevValues: any) => {
                    const updated = { ...prevValues, [props.item.linkId]: newValue };
                    return updated;
                  });
                  onChangeAnswer({ valueString: newValue }, index);
                }}
                onInput={
                  props.item.text === 'Practice TIN/EIN'
                    ? (event) => handleCheckValidity(event, props.item.text)
                    : props.item.text === 'NPI 1' ||
                      props.item.text === 'NPI 2' ||
                      props.item.text === 'License Number' ||
                      props.item.text === 'Practice Phone' ||
                      props.item.text === 'Practice Fax' ||
                      props.item.text === 'Practice Email\n' ||
                      props.item.text === 'Personal Cell' ||
                      props.item.text === 'Personal Email'
                      ? (event) => handleInput(event, props.item.text)
                      : () => { }
                }
              />
              {errors[props.item.text] && <span className="error">{errors[props.item.text]}</span>}
            </>)
      );
    case QuestionnaireItemType.text:
      return (
        <Textarea
          id={name}
          name={name}
          required={item.required}
          defaultValue={initial?.valueString}
          onChange={(e) => onChangeAnswer({ valueString: e.currentTarget.value }, index)}
        />
      );
    case QuestionnaireItemType.attachment:
      return (
        <Group py={4}>
          <AttachmentInput
            name={name}
            defaultValue={initial?.valueAttachment || props.answers?.[props.item.linkId]?.valueAttachment || ''}
            onChange={(newValue) => onChangeAnswer({ valueAttachment: newValue }, index)}
          />
        </Group>
      );
    case QuestionnaireItemType.reference:
      return (
        <ReferenceInput
          name={name}
          required={item.required}
          targetTypes={addTargetTypes(item)}
          defaultValue={initial?.valueReference}
          onChange={(newValue) => onChangeAnswer({ valueReference: newValue }, index)}
        />
      );
    case QuestionnaireItemType.quantity:
      return (
        <QuantityInput
          name={name}
          required={item.required}
          defaultValue={initial?.valueQuantity}
          onChange={(newValue) => onChangeAnswer({ valueQuantity: newValue }, index)}
          disableWheel
        />
      );
    case QuestionnaireItemType.choice:
    case QuestionnaireItemType.openChoice:
      if (isDropDownChoice(item) && !item.answerValueSet) {
        return (
          <QuestionnaireChoiceDropDownInput
            name={name}
            item={item}
            initial={initial}
            allResponses={props.allResponses}
            onChangeAnswer={(e: any) => onChangeAnswer(e, index)}
          />
        );
      } else {
        return (
          <QuestionnaireChoiceRadioInput
            name={name}
            item={item}
            initial={initial}
            allResponses={props.allResponses}
            onChangeAnswer={(e: any) => onChangeAnswer(e, index)}
          />
        );
      }
    default:
      return null;
  }
}

interface MultiSelect {
  value: any;
  label: any;
}

interface FormattedData {
  propertyName: string;
  data: MultiSelect[];
}

function formatSelectData(item: QuestionnaireItem): FormattedData {
  if (item.answerOption?.length === 0) {
    return { propertyName: '', data: [] };
  }
  const option = (item.answerOption as QuestionnaireItemAnswerOption[])[0];
  const optionValue = getTypedPropertyValue(
    { type: 'QuestionnaireItemAnswerOption', value: option },
    'value'
  ) as TypedValue;
  const propertyName = 'value' + capitalize(optionValue.type);

  const data = (item.answerOption ?? []).map((a) => ({
    value: getValueAndLabel(a, propertyName),
    label: getValueAndLabel(a, propertyName),
  }));
  return { propertyName, data };
}

function getValueAndLabel(option: QuestionnaireItemAnswerOption, propertyName: string): string | undefined {
  return formatCoding(option.valueCoding) || option[propertyName as keyof QuestionnaireItemAnswerOption]?.toString();
}

interface QuestionnaireChoiceInputProps {
  name: string;
  item: QuestionnaireItem;
  initial: QuestionnaireItemInitial | undefined;
  allResponses: QuestionnaireResponseItem[];
  index?: number;
  groupSequence?: number;
  onChangeAnswer: (newResponseAnswer: QuestionnaireResponseItemAnswer | QuestionnaireResponseItemAnswer[]) => void;
}

function QuestionnaireChoiceDropDownInput(props: QuestionnaireChoiceInputProps): JSX.Element {
  const { name, item, initial } = props;
  const initialValue = getTypedPropertyValue({ type: 'QuestionnaireItemInitial', value: initial }, 'value') as
    | TypedValue
    | undefined;

  const data = [''];
  if (item.answerOption) {
    for (const option of item.answerOption) {
      const optionValue = getTypedPropertyValue(
        { type: 'QuestionnaireItemAnswerOption', value: option },
        'value'
      ) as TypedValue;
      data.push(typedValueToString(optionValue) as string);
    }
  }

  const defaultValue =
    getCurrentAnswer(props.allResponses, item, props.index, props.groupSequence) ??
    getTypedPropertyValue({ type: 'QuestionnaireItemInitial', value: initial }, 'value');

  if (item.type === QuestionnaireItemType.openChoice) {
    const { propertyName, data } = formatSelectData(props.item);
    const currentAnswer = getCurrentMultiSelectAnswer(props.allResponses, item, props.groupSequence);

    return (
      <MultiSelect
        data={data}
        placeholder="Select items"
        searchable
        defaultValue={currentAnswer || [typedValueToString(initialValue)]}
        onChange={(selected) => {
          const values = getNewMultiSelectValues(selected, propertyName, item);
          props.onChangeAnswer(values);
        }}
      />
    );
  }

  return (
    <NativeSelect
      id={name}
      name={name}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        const index = e.currentTarget.selectedIndex;
        if (index === 0) {
          props.onChangeAnswer({});
          return;
        }
        const option = (item.answerOption as QuestionnaireItemAnswerOption[])[index - 1];
        const optionValue = getTypedPropertyValue(
          { type: 'QuestionnaireItemAnswerOption', value: option },
          'value'
        ) as TypedValue;
        const propertyName = 'value' + capitalize(optionValue.type);
        props.onChangeAnswer({ [propertyName]: optionValue.value });
      }}
      defaultValue={(formatCoding(defaultValue?.value) || defaultValue?.value) ?? typedValueToString(initialValue)}
      data={data}
    />
  );
}

function typedValueToString(typedValue: TypedValue | undefined): string | undefined {
  if (!typedValue) {
    return undefined;
  }
  if (typedValue.type === 'CodeableConcept') {
    return typedValue.value.coding[0].display;
  }
  if (typedValue.type === 'Coding') {
    return typedValue.value.display;
  }
  return typedValue.value.toString();
}

function getItemsByLinkId(allResponses: QuestionnaireResponseItem[], linkId: string): QuestionnaireResponseItem[] {
  let result: QuestionnaireResponseItem[] = [];

  for (const item of allResponses) {
    if (item.linkId === linkId) {
      result.push(item);
    }

    if (item.item) {
      result = result.concat(getItemsByLinkId(item.item, linkId));
    }
  }
  return result;
}

function getItemValue(answer: QuestionnaireResponseItemAnswer): TypedValue {
  const itemValue = getTypedPropertyValue({ type: 'QuestionnaireItemAnswer', value: answer }, 'value') as TypedValue;
  return itemValue;
}

function getCurrentAnswer(
  allResponses: QuestionnaireResponseItem[],
  item: QuestionnaireItem,
  index: number = 0,
  groupSequence: number = 0
): TypedValue {
  const results = getItemsByLinkId(allResponses, item.linkId ?? '');
  const selectedItem = results[groupSequence]?.answer;
  return getItemValue(selectedItem?.[index] ?? {});
}

function getCurrentMultiSelectAnswer(
  allResponses: QuestionnaireResponseItem[],
  item: QuestionnaireItem,
  groupSequence: number = 0
): string[] {
  const results = getItemsByLinkId(allResponses, item.linkId ?? '');
  const selectedItem = results[groupSequence]?.answer;
  if (!selectedItem) {
    return [];
  }
  const typedValues = selectedItem.map((a) => getItemValue(a));
  return typedValues.map((type) => formatCoding(type?.value) || type?.value);
}

function QuestionnaireChoiceRadioInput(props: QuestionnaireChoiceInputProps): JSX.Element {
  const { name, item, initial, onChangeAnswer } = props;
  const valueElementDefinition = getElementDefinition('QuestionnaireItemAnswerOption', 'value[x]');
  const initialValue = getTypedPropertyValue({ type: 'QuestionnaireItemInitial', value: initial }, 'value') as
    | TypedValue
    | undefined;

  const options: [string, TypedValue][] = [];
  let defaultValue = undefined;
  if (item.answerOption) {
    for (let i = 0; i < item.answerOption.length; i++) {
      const option = item.answerOption[i];
      const optionName = `${name}-option-${i}`;
      const optionValue = getTypedPropertyValue(
        { type: 'QuestionnaireItemAnswerOption', value: option },
        'value'
      ) as TypedValue;

      if (initialValue && stringify(optionValue) === stringify(initialValue)) {
        defaultValue = optionName;
      }
      options.push([optionName, optionValue]);
    }
  }

  return (
    <Radio.Group
      name={name}
      defaultValue={defaultValue}
      onChange={(newValue) => {
        const option = options.find((option) => option[0] === newValue);
        if (option) {
          const optionValue = option[1];
          const propertyName = 'value' + capitalize(optionValue.type);
          onChangeAnswer({ [propertyName]: optionValue.value });
        }
      }}
    >
      {options.map(([optionName, optionValue]) => (
        <Radio
          key={optionName}
          id={optionName}
          value={optionName}
          label={
            <ResourcePropertyDisplay
              property={valueElementDefinition}
              propertyType={optionValue.type}
              value={optionValue.value}
            />
          }
        />
      ))}
    </Radio.Group>
  );
}

function buildInitialResponseItems(items: QuestionnaireItem[] | undefined): QuestionnaireResponseItem[] {
  return items?.map(buildInitialResponseItem) ?? [];
}

function buildInitialResponseItem(item: QuestionnaireItem): QuestionnaireResponseItem {
  return {
    id: generateId(),
    linkId: item.linkId,
    text: item.text,
    item: buildInitialResponseItems(item.item),
    answer: item.initial?.map(buildInitialResponseAnswer) ?? [],
  };
}

let nextId = 1;
function generateId(): string {
  return 'id-' + nextId++;
}

function buildInitialResponseAnswer(answer: QuestionnaireItemInitial): QuestionnaireResponseItemAnswer {
  return { ...answer };
}

function isDropDownChoice(item: QuestionnaireItem): boolean {
  return item?.type === QuestionnaireItemType.openChoice;
}

export function isQuestionEnabled(
  item: QuestionnaireItem,
  answers: Record<string, QuestionnaireResponseItemAnswer>
): boolean {
  if (!item.enableWhen) {
    return true;
  }

  const enableBehavior = item.enableBehavior ?? 'any';

  for (const enableWhen of item.enableWhen) {
    const actualAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireResponseItemAnswer',
        value: answers[enableWhen.question as string],
      },
      'value[x]'
    ) as TypedValue | undefined; // possibly undefined when question unanswered

    const expectedAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireItemEnableWhen',
        value: enableWhen,
      },
      'answer[x]'
    ) as TypedValue;

    let match: boolean;

    const { operator } = enableWhen;

    // We handle exists separately since its so different in terms of comparisons than the other mathematical operators
    if (operator === 'exists') {
      // if actualAnswer is not undefined, then exists: true passes
      // if actualAnswer is undefined, then exists: false passes
      match = !!actualAnswer === expectedAnswer.value;
    } else if (actualAnswer === undefined) {
      match = false;
    } else {
      // `=` and `!=` should be treated as the FHIRPath `~` and `!~`
      // All other operators should be unmodified
      const fhirPathOperator = operator === '=' || operator === '!=' ? operator?.replace('=', '~') : operator;
      const [{ value }] = evalFhirPathTyped(`%actualAnswer ${fhirPathOperator} %expectedAnswer`, [actualAnswer], {
        actualAnswer,
        expectedAnswer,
      });
      match = value;
    }

    if (enableBehavior === 'any' && match) {
      return true;
    }
    if (enableBehavior === 'all' && !match) {
      return false;
    }
  }
  if (enableBehavior === 'any') {
    return false;
  } else {
    return true;
  }
}

function addTargetTypes(item: QuestionnaireItem): string[] {
  if (item.type !== QuestionnaireItemType.reference) {
    return [];
  }
  const extensions = item.extension?.filter(
    (e) => e.url === 'http://hl7.org/fhir/StructureDefinition/questionnaire-referenceResource'
  );
  if (!extensions || extensions.length === 0) {
    return [];
  }
  const targets = extensions.map((e) => e.valueCodeableConcept?.coding?.[0]?.code) as string[];
  return targets;
}

interface RepeatableGroupProps {
  item: QuestionnaireItem;
  text: string;
  answers: Record<string, QuestionnaireResponseItemAnswer>;
  onChange: (newResponseItem: QuestionnaireResponseItem[], index?: number) => void;
}

function RepeatableGroup(props: RepeatableGroupProps): JSX.Element | null {
  const [number, setNumber] = useState(1);

  const item = props.item;
  return (
    <>
      {[...Array(number)].map((_, i) => {
        return (
          <div key={i}>
            <h3>{props.text}</h3>
            <QuestionnaireFormItemArray
              items={item.item ?? []}
              answers={props.answers}
              onChange={(response) => props.onChange(response, i)}
            />
          </div>
        );
      })}
      {props.item.repeats && <Anchor onClick={() => setNumber((n) => n + 1)}>Add Group</Anchor>}
    </>
  );
}

interface RepeatableItemProps {
  item: QuestionnaireItem;
  children: (props: { index: number }) => JSX.Element;
}

function RepeatableItem(props: RepeatableItemProps): JSX.Element {
  const [number, setNumber] = useState(1);
  return (
    <>
      {[...Array(number)].map((_, i) => {
        return <React.Fragment key={`${props.item.linkId}-${i}`}>{props.children({ index: i })}</React.Fragment>;
      })}
      {props.item?.repeats && <Anchor onClick={() => setNumber((n) => n + 1)}>Add Item</Anchor>}
    </>
  );
}

function updateAnswerArray(
  answers: QuestionnaireResponseItemAnswer[],
  index: number,
  newResponseAnswer: QuestionnaireResponseItemAnswer
): QuestionnaireResponseItemAnswer[] {
  if (index < answers.length) {
    if (newResponseAnswer.valueString !== "") {
      answers[index] = newResponseAnswer;
      return answers;
    }
    return [];
  } else {
    for (let i = answers.length; i < index; i++) {
      answers.push({});
    }
    answers.push(newResponseAnswer);
    return answers;
  }
}

function getResponseId(responses: QuestionnaireResponseItem[], index: number): string {
  if (responses.length === 0 || responses.length < index + 1) {
    return generateId();
  }
  return responses[index].id as string;
}

/* eslint-disable react-refresh/only-export-components */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-debugger */
import { Button, Group, Loader } from '@mantine/core';
import {
  evalFhirPathTyped,
  getExtension,
  getQuestionnaireAnswers,
  getReferenceString,
  getTypedPropertyValue,
  TypedValue,
} from '@medplum/core';
import {
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireItemInitial,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
  Reference,
} from '@medplum/fhirtypes';
import React, { useEffect, useRef, useState } from 'react';
import { Form, useResource } from '@medplum/react';
import { QuestionnaireFormItemArray } from './QuestionnaireFormItemArray';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import { useAppContext } from '../AppProvider';
import { Stepper } from './Stepper';
import { toast } from 'react-toastify';

export interface QuestionnaireFormProps {
  questionnaire: Questionnaire | Reference<Questionnaire>;
  subject?: Reference;
  submitButtonText?: string;
  onSubmit: (response: QuestionnaireResponse) => void;
}

export function QuestionnaireForm(props: QuestionnaireFormProps): JSX.Element | null {
  const questionnaire = useResource(props.questionnaire);
  const [response, setResponse] = useState<QuestionnaireResponse | undefined>();
  const [answers, setAnswers] = useState<Record<string, QuestionnaireResponseItemAnswer>>({});
  const [activePage, setActivePage] = useState(0);
  const [activeStep, setActiveStep] = useState(2);
  const divRef = useRef<HTMLDivElement>(null);
  const { setRegisterPageHeight, isPasswordStepper, setPasswordStepper, isPasswordEntryStepperActive, setPasswordEntryStepperActive, } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [numberOfPages, setNumberOfPages] = useState(getNumberOfPages(questionnaire?.item ?? []));

  const nextStep = (): void => {
    setPasswordStepper(activePage === 0);
    if (activePage === 0 && response?.item) {
      setLoading(true);

      if (!validatePassword(response)) {
        setLoading(false);
        setPasswordStepper(false);
        return;
      }

      try {
        if (props.onSubmit && response) {
          props.onSubmit({
            ...response,
            questionnaire: questionnaire ? getReferenceString(questionnaire) : '',
            subject: props.subject,
            source: undefined,
            authored: new Date().toISOString(),
            status: 'completed',
          });
        }
      } catch (error) {
        setLoading(false);
        setPasswordStepper(false);
        console.error('Error submitting form:', error);
      } finally {
        setLoading(false);
      }
    }
    if (activePage !== 0) {
      setActivePage((current) => (current >= numberOfPages ? current : current + 1));
    }
  };

  const prevStep = (): void => setActivePage((current) => (current <= 0 ? current : current - 1));

  useEffect(() => {
    if (isPasswordEntryStepperActive && activePage === 0) {
      setPasswordEntryStepperActive(false);
      setPasswordStepper(false);
      setActivePage((current) => (current >= numberOfPages ? current : current + 1));
    }
  }, [isPasswordEntryStepperActive]);

  useEffect(() => {
    setResponse(questionnaire ? buildInitialResponse(questionnaire) : undefined);
  }, [questionnaire]);

  useEffect(() => {
    updateFormHeight();
  }, [divRef, activePage, response]);


  useEffect(() => {
    const currentItem = questionnaire?.item?.[activePage];
    if (currentItem) {
      if (currentItem.linkId === 'g33' && currentItem.text === 'Create Your Login') {
        setActiveStep(2);
      } else if (
        currentItem.linkId === 'g5' &&
        currentItem.text ===
        'Your Information: Your preferred full formal title if filling out forms or letters for patients. For example: Board Certified Psychiatrist or Psychiatric Physician Associate.'
      ) {
        setActiveStep(2);
      } else if (
        currentItem.linkId === 'g11' &&
        currentItem.text ===
        'Practice Address: To have customized test orders, letters to patients, and letterhead, please enter your practice’s corporate or main mailing address.'
      ) {
        setActiveStep(3);
      }
      else if (
        currentItem.linkId === 'g29' &&
        currentItem.text ===
        "Personal information: Not to be included with any patient information. Used for password retrieval and contact if needed"
      ) {
        setActiveStep(4);
      }
    }
  }, [questionnaire, activePage]);


  const updateFormHeight = () => {
    if (divRef.current) {
      const formRect = divRef.current.getBoundingClientRect();
      const height = Math.round(formRect.height) + 200;
      if (height < 680) {
        setRegisterPageHeight('100vh');
      } else {
        setRegisterPageHeight(`${height}px`);
      }
    }
  };


  function setItems(newResponseItems: QuestionnaireResponseItem[]): void {
    const newResponse: QuestionnaireResponse = {
      resourceType: 'QuestionnaireResponse',
      item: newResponseItems,
    };
    setResponse(newResponse);
    setAnswers(getQuestionnaireAnswers(newResponse));
  }

  function isStrongPassword(password: string): boolean {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  }

  if (!questionnaire) {
    return null;
  }

  //const pagesArray = Array.from({ length: numberOfPages }, (_, index) => index + 1);

  const isRequired = questionnaire?.item?.[activePage]?.item?.some((item) => item?.required) ||
    questionnaire?.item?.[activePage]?.required === true;

  function validatePassword(response: any): boolean {
    const createYourLoginItem = response?.item?.find((item: any) => item.linkId === 'g33');

    if (createYourLoginItem) {
      const passwordItem = createYourLoginItem?.item?.find(
        (item: any) => item.linkId === 'Q23' && item.text === 'Password'
      );

      if (passwordItem && passwordItem?.answer && passwordItem?.answer?.length > 0) {
        const passwordValue = passwordItem?.answer[0]?.valueString || '';

        if (!isStrongPassword(passwordValue)) {
          toast.error('Invalid password entered.');
          return false;
        }
      }
    }
    return true;
  }

  return (
    <div ref={divRef}>
      <Stepper totalSteps={numberOfPages + 1} activeStep={activeStep} setActiveStep={setActiveStep} />
      <Form
        testid="questionnaire-form"
        onSubmit={async () => {
          setLoading(true);
          try {
            if (props.onSubmit && response) {
              if (response.item && !validatePassword(response)) {
                setLoading(false);
                return;
              }
              await props.onSubmit({
                ...response,
                questionnaire: getReferenceString(questionnaire),
                subject: props.subject,
                source: undefined,
                authored: new Date().toISOString(),
                status: 'completed',
              });
            }
          } catch (error) {
            setLoading(false);
            console.error('Error submitting form:', error);
          } finally {
            setLoading(false);
          }
        }
        }
      >
        {questionnaire.item && (
          <QuestionnaireFormItemArray
            items={questionnaire.item ?? []}
            answers={answers}
            onChange={setItems}
            renderPages={numberOfPages > 1}
            activePage={activePage}
            updateNumberOfPages={setNumberOfPages}
          />
        )}
        <Group position="right" my="xl" className="stepper-buttons">
          <ButtonGroup
            activePage={activePage}
            numberOfPages={numberOfPages}
            nextStep={nextStep}
            prevStep={prevStep}
            submitButtonText={props.submitButtonText}
            isRequired={isRequired}
            loading={loading}
            isPasswordStepperLoader={isPasswordStepper}
          />
        </Group>
      </Form>
    </div>
  );
}

interface ButtonGroupProps {
  activePage: number;
  numberOfPages: number;
  submitButtonText?: string;
  nextStep: () => void;
  prevStep: () => void;
  isRequired: any;
  loading: boolean;
  isPasswordStepperLoader?: boolean;
}

function ButtonGroup(props: ButtonGroupProps): JSX.Element {
  const showBackButton = props.activePage > 0;
  const showNextButton = props.activePage < props.numberOfPages - 1;
  const showSubmitButton = props.activePage === props.numberOfPages - 1;

  return (
    <>
      {!(showBackButton || showSubmitButton) && <div></div>}
      {showBackButton && <Button className="bg-main" onClick={props.prevStep}>
        <IconArrowNarrowLeft size={18} style={{ marginRight: '5px' }} /> Previous</Button>}
      {(showNextButton && props?.isRequired) && (
        <Button
          className="bg-main"
          onClick={(e) => {
            const form = e.currentTarget.closest('form') as HTMLFormElement;
            if (form.reportValidity()) {
              props.nextStep();
            }
          }}
          disabled={props.isPasswordStepperLoader}
        >
          {props.isPasswordStepperLoader ? (
            <>
              <Loader size="sm" color="white" style={{ marginRight: '5px' }} />
              <span style={{ color: 'aliceblue' }}>Processing...</span>
            </>
          ) : (
            <>
              Next <IconArrowNarrowRight size={18} style={{ marginLeft: '3px' }} />
            </>
          )}
        </Button>
      )}
      {!props?.isRequired && !showSubmitButton && <Button
        className="bg-main"
        onClick={(e) => {
          const form = e.currentTarget.closest('form') as HTMLFormElement;
          if (form.reportValidity()) {
            props.nextStep();
          }
        }}
      >
        ENTER <IconArrowNarrowRight size={18} style={{ marginLeft: '3px' }} />
      </Button>}

      {showSubmitButton && (
        <Button className="bg-main" type="submit" disabled={props.loading}>
          {props.loading ? (
            <>
              <Loader color='#fff' size="xs" /> <span className='btnLoader'>Submitting...</span>
            </>
          ) : (
            props.submitButtonText ?? 'Submit'
          )}
        </Button>
      )}
    </>
  );
}

function buildInitialResponse(questionnaire: Questionnaire): QuestionnaireResponse {
  const response: QuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    questionnaire: getReferenceString(questionnaire),
    item: buildInitialResponseItems(questionnaire.item),
  };

  return response;
}

function buildInitialResponseItems(items: QuestionnaireItem[] | undefined): QuestionnaireResponseItem[] {
  return items?.map(buildInitialResponseItem) ?? [];
}

function buildInitialResponseItem(item: QuestionnaireItem): QuestionnaireResponseItem {
  return {
    id: generateId(),
    linkId: item.linkId,
    text: item.text,
    item: buildInitialResponseItems(item.item),
    answer: item.initial?.map(buildInitialResponseAnswer) ?? [],
  };
}

let nextId = 1;
function generateId(): string {
  return 'id-' + nextId++;
}

function buildInitialResponseAnswer(answer: QuestionnaireItemInitial): QuestionnaireResponseItemAnswer {
  return { ...answer };
}

export function isQuestionEnabled(
  item: QuestionnaireItem,
  answers: Record<string, QuestionnaireResponseItemAnswer>
): boolean {
  if (!item.enableWhen) {
    return true;
  }

  const enableBehavior = item.enableBehavior ?? 'any';

  for (const enableWhen of item.enableWhen) {
    const actualAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireResponseItemAnswer',
        value: answers[enableWhen.question as string],
      },
      'value[x]'
    ) as TypedValue | undefined; // possibly undefined when question unanswered

    const expectedAnswer = getTypedPropertyValue(
      {
        type: 'QuestionnaireItemEnableWhen',
        value: enableWhen,
      },
      'answer[x]'
    ) as TypedValue;

    let match: boolean;

    const { operator } = enableWhen;

    // We handle exists separately since its so different in terms of comparisons than the other mathematical operators
    if (operator === 'exists') {
      // if actualAnswer is not undefined, then exists: true passes
      // if actualAnswer is undefined, then exists: false passes
      match = !!actualAnswer === expectedAnswer.value;
    } else if (actualAnswer === undefined) {
      match = false;
    } else {
      // `=` and `!=` should be treated as the FHIRPath `~` and `!~`
      // All other operators should be unmodified
      const fhirPathOperator = operator === '=' || operator === '!=' ? operator?.replace('=', '~') : operator;
      const [{ value }] = evalFhirPathTyped(`%actualAnswer ${fhirPathOperator} %expectedAnswer`, [actualAnswer], {
        actualAnswer,
        expectedAnswer,
      });
      match = value;
    }

    if (enableBehavior === 'any' && match) {
      return true;
    }
    if (enableBehavior === 'all' && !match) {
      return false;
    }
  }
  if (enableBehavior === 'any') {
    return false;
  } else {
    return true;
  }
}

function getNumberOfPages(items: QuestionnaireItem[]): number {
  items.filter((item) => {
    const extension = getExtension(item, 'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl');
    return extension?.valueCodeableConcept?.coding?.[0]?.code === 'page';
  });
  return items.length;
}

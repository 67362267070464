/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Grid, createStyles, Tabs, rem, Text, Tooltip } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTemplateListAccordingToRole, getPractitionerRole } from '../utils/util';
import { useMedplum } from '@medplum/react';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppProvider';

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.md,
  },
}));

export interface TemplateNoteProps {
  selectedTemplateNote: (selectedItem: any) => void;
  summary: any;
}

const TemplateList: React.FC<TemplateNoteProps> = (props: TemplateNoteProps) => {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts[1];
  const [disabledButtons, setDisabledButtons] = useState<string[]>([]);
  const [visitTypeDisabledButtons, setVisitTypeDisabledButtons] = useState<string | null>(null);
  const [visitType, setVisitType] = useState<any[]>([]);
  const [additionalNotes, setAdditionalNotes] = useState<any[]>([]);
  const [customNotes, setCustomNotes] = useState<any[]>([]);
  const [requestedNotes, setRequestedNotes] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>('visitType');
  const { setIsSegmentedNote, setSegmentedNoteTitle, isTemplateListBtnDisabled, setTemplateListBtnDisabled, customTextareaRef, setModifiedContent, setIsNoteModified,
    setIsUpdatingSoapWhileCreatingTemplate, isProceedToTemplateNoteCreation, setIsProceedToTemplateNoteCreation, noteRegenerateLoader, setSaveUnKnownSessionWhileRedirection,
    isSessionOn, isSaveTranscripts, 
  } = useAppContext();
  const [templateName, setTemplateName] = useState<boolean>(false);
  const [handleClickData, setHandleClickData] = useState<any>(null);
  const [handleClickEvent, setHandleClickEvent] = useState<string>('');
  const [isNoteUpdateMessageVisible, setIsNoteUpdateMessageVisible] = useState<boolean>(false);

  useEffect(() => {
    getPractitionerRole(medplum, practitionerId)
      .then((response: any) => {
        const specialtyCode = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        getTemplateListAccordingToRole(medplum, specialtyCode)
          .then((response: any) => {
            const visitTypeEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'visit-type');
            const visitType = visitTypeEntries?.map((item: { resource: { title: string } }) => item.resource)
            setVisitType(visitType)

            const additionaNotesEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'additional-note');
            const noteTitles = ['Psychotherapy Add-On Note', 'Patient Instructions', 'SOAP (Beta)', 'Patient Instructions (Conversational)'];

            const requestedNotes = additionaNotesEntries
              ?.filter((entry: any) => noteTitles.includes(entry.resource.title))
              .map((entry: any) => entry.resource);
            setRequestedNotes(requestedNotes);

            if (requestedNotes.length !== 0) {
              const additionalNotes = additionaNotesEntries
                ?.filter((entry: any) => !noteTitles.includes(entry.resource.title))
                .map((entry: any) => entry.resource);
              setAdditionalNotes(additionalNotes);
            }

            const customNotesEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'custom-note');
            const customNotesOrder = customNotesEntries?.map((item: { resource: { title: string } }) => item.resource);
            setCustomNotes(customNotesOrder);

            if (props?.summary) {
              const summaryCompositionIds = props?.summary.map((entry: { compositionId: any; }) => entry.compositionId);
              setDisabledButtons(summaryCompositionIds);
              const matchingVisits = visitType.filter((visit: any) => {
                return props?.summary.some((entry: any) => entry.compositionId === visit.id);
              });

              // Set matching visits in visitTypeDisabledButtons
              setVisitTypeDisabledButtons(matchingVisits[0]?.id);
            }
          })
          .catch((error: any) => {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch session details.');
          });
      })
      .catch((error: any) => {
        console.error('Error fetching practitioner role:', error);
        toast.error('Failed to fetch practitioner role.');
      });
  }, []);

  useEffect(() => {
    if (isProceedToTemplateNoteCreation) {
      executeTemplateGenerate(handleClickData, handleClickEvent);
      setIsProceedToTemplateNoteCreation(false);
      setHandleClickData(null);
      setHandleClickEvent('');
    }
  }, [isProceedToTemplateNoteCreation]);

  const handleClick = (data: any, event: string) => {
    setTemplateListBtnDisabled(true);

    if (window.location.href.includes('/Appointment') && (isSessionOn || isSaveTranscripts)) {
      //save the unknown session transcript before redirection
      setSaveUnKnownSessionWhileRedirection(true);
      executeTemplateGenerate(data, event);
    } else if ((event != 'visitType' || isNoteUpdateMessageVisible) && window.location.href.includes('/Appointment') && customTextareaRef.current?.getEditorValue()) {
      setModifiedContent(customTextareaRef?.current?.getEditorValue());
      setIsNoteUpdateMessageVisible(true);
      setIsNoteModified(true);
      setHandleClickData(data);
      setHandleClickEvent(event);
      setTemplateName(true);
    }
    else if (!customTextareaRef.current?.getEditorValue() && (event != 'visitType' || isNoteUpdateMessageVisible)) {
      setIsNoteUpdateMessageVisible(true);
      setIsUpdatingSoapWhileCreatingTemplate(true);
      setHandleClickData(data);
      setHandleClickEvent(event);
    }
    else {
      executeTemplateGenerate(data, event);
      setIsNoteUpdateMessageVisible(false);
    }
  };

  const executeTemplateGenerate = (data: any, event: string) => {
    setIsSegmentedNote(false);
    setSegmentedNoteTitle(data.title)
    if (data.title.includes("SOAP")) {
      setIsSegmentedNote(true);
    }
    else {
      setIsSegmentedNote(false);
    }
    if (event === 'visitType') {
      if (visitTypeDisabledButtons === data.id) {
        setVisitTypeDisabledButtons(null);
      } else {
        setVisitTypeDisabledButtons(data.id);
      }
    } else {
      setDisabledButtons(prevDisabledButtons => [...prevDisabledButtons, data.id]);
    }
    props.selectedTemplateNote(data)
  }

  useEffect(() => {
    if (templateName) {
      executeTemplateGenerate(handleClickData, handleClickEvent);
      setTemplateName(false);
    }
  }, [templateName]);


  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const getButtonStyles = (isDisabled: any) => ({
    paddingLeft: "10px",
    paddingRight: "10px",
    justifyContent: "center",
    borderColor: isDisabled ? 'transparent' : '',
    backgroundColor: isDisabled ? '#e9ecef' : '',
    color: isDisabled ? '#adb5bd' : '',
    backgroundImage: isDisabled ? 'none' : '',
  });

  return (
    <>
      <Card
        p={5}
        className={noteRegenerateLoader || isTemplateListBtnDisabled ? "disabled-card" : ""}
      >
        <Card.Section className={classes.section} style={{ borderBottom: '0px !important' }}>
          <Grid columns={12}>
            <Grid.Col span={12} md={12} lg={12} className='tamplates'>
              <Tabs
                defaultValue="visitType"
                value={activeTab}
                onTabChange={handleTabChange}
                unstyled
                styles={(theme) => ({
                  tab: {
                    ...theme.fn.focusStyles(),
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
                    border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
                    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
                    cursor: 'pointer',
                    fontSize: theme.fontSizes.sm,
                    display: 'flex',
                    alignItems: 'center',

                    '&:disabled': {
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    },

                    '&:not(:first-of-type)': {
                      borderLeft: 0,
                    },

                    '&:first-of-type': {
                      borderTopLeftRadius: theme.radius.md,
                      borderBottomLeftRadius: theme.radius.md,
                    },

                    '&:last-of-type': {
                      borderTopRightRadius: theme.radius.md,
                      borderBottomRightRadius: theme.radius.md,
                    },

                    '&[data-active]': {
                      backgroundColor: '#3077CA',
                      color: theme.white,
                      paddingLeft: '10px',
                      justifyContent: 'center',
                    },
                  },
                  tabIcon: {
                    marginRight: theme.spacing.xs,
                    display: 'flex',
                    alignItems: 'center',
                  },
                  tabsList: {
                    display: 'flex',
                  },
                })}
              >
                <Tabs.List mb={10} sx={{ justifyContent: 'center' }}>
                  <Tabs.Tab className="template-list-tab centerContent" value="visitType">
                    <img
                      src={activeTab === 'visitType'
                        ? "../../../../img/icons/visit-type-fill.svg"
                        : "../../../../img/icons/visit-type.svg"}
                      height={24}
                      className="tab-icon"
                      alt="Visit Type Icon"
                    />
                    <br />
                    <span>Visit Type</span>
                  </Tabs.Tab>
                  <Tabs.Tab className="template-list-tab  centerContent" value="additionalNotes">
                    <Tooltip label="Orders, Letters, Referrals, Statements of Service" position="top" withArrow>
                      <div>
                        <img
                          src={activeTab === 'additionalNotes'
                            ? "../../../../img/icons/practice-white.svg"
                            : "../../../../img/icons/practice-fill.svg"}
                          height={24}
                          className="tab-icon"
                          alt="Additional Notes Icon"
                        />
                        <br />
                        <span>Practice Tools</span>
                      </div>
                    </Tooltip>
                  </Tabs.Tab>

                  <Tabs.Tab className="template-list-tab centerContent" value="customNotes">
                    <img
                      src={activeTab === 'customNotes'
                        ? "../../../../img/icons/custome-edit-fill.svg"
                        : "../../../../img/icons/custome-edit.svg"}
                      height={24}
                      className="tab-icon"
                      alt="Custom Notes Icon"
                    />
                    <br />
                    <span>Custom Notes</span>
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="visitType">
                  <div className="tabContainer">
                    {visitType?.map((data: any) => {
                      const isLongTitle = data?.title?.length > 35;
                      return (
                        <div key={data.id} className="template-div">
                          <div
                            style={{
                              cursor: isTemplateListBtnDisabled ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <Tooltip label="Regenerate Note"
                              position={isLongTitle ? "top" : "right"}
                              withArrow >
                              <button className="template-option"
                                onClick={() => handleClick(data, 'visitType')}
                                disabled={visitTypeDisabledButtons === data.id || isTemplateListBtnDisabled}
                                style={getButtonStyles(isTemplateListBtnDisabled)}
                              >
                                {data?.title}
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    }
                    )}
                  </div>
                  <hr style={{ border: "2px solid skyblue", width: "100%", marginTop: "20px", marginBottom: "10px" }} />
                  <Text fw={500} size={20} color='blue' underline pb={15} align='center'>Generate Additional Notes</Text>
                  <div className="tabContainer">
                    {requestedNotes?.map((data: any) => (
                      <div key={data.id} className="template-div">
                        <div
                          style={{
                            cursor: isTemplateListBtnDisabled ? 'not-allowed' : 'pointer',
                          }}
                        >
                          <Tooltip label="Add Note" position="left" withArrow>
                            <button className="template-option"
                              onClick={() => handleClick(data, 'additionalNotes')}
                              disabled={disabledButtons.includes(data.id) || isTemplateListBtnDisabled}
                              style={getButtonStyles(isTemplateListBtnDisabled)}
                            >
                              {data?.title}
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="additionalNotes">
                  <div className="tabContainer">
                    {additionalNotes?.map((data: any) => {
                      const isLongTitle = data?.title?.length > 35;
                      return (
                        <div key={data.id} className="template-div">
                          <div
                            style={{
                              cursor: isTemplateListBtnDisabled ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <Tooltip
                              label="Add Note"
                              position={isLongTitle ? "top" : "left"}
                              withArrow
                            >
                              <button
                                className="template-option"
                                onClick={() => handleClick(data, 'additionalNotes')}
                                disabled={disabledButtons.includes(data.id) || isTemplateListBtnDisabled}
                                style={getButtonStyles(isTemplateListBtnDisabled)}
                              >
                                {data?.title}
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="customNotes">
                  <div className="tabContainer">
                    {customNotes && customNotes.length > 0 ? (
                      customNotes.map((data: any) => (
                        <div key={data.id} className="template-div">
                          <div
                            style={{
                              cursor: isTemplateListBtnDisabled ? 'not-allowed' : 'pointer',
                            }}
                          >
                            <button
                              className="template-option"
                              onClick={() => handleClick(data, 'customNotes')}
                              disabled={disabledButtons.includes(data.id) || isTemplateListBtnDisabled}
                              style={getButtonStyles(isTemplateListBtnDisabled)}
                            >
                              {data?.title}
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <Text fw={500} size={18} color="grey">
                          Coming Soon...
                        </Text>
                      </div>
                    )}
                  </div>
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
    </>
  );
};

export default TemplateList;
import { Box, Button, Grid, TextInput, Title, Text, Anchor, Checkbox } from '@mantine/core'
import React, { useState } from 'react'
import classes from './SignUp.module.css';
import { createStripeSsession, getStripeRenewSession, handleNonNpiReasonResponse, npiResponse } from '../utils/util';
import { useMedplum } from '@medplum/react';
import { toast } from 'react-toastify';
import InvitePopup from '../patients/InvitePopup';
import RoleSelectionPopup from './RoleSelectionPopup';

interface PractitionerDetails {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  withNPI?: boolean;
  reason?: string;
}

function SignUp() {
  const medplum = useMedplum();
  const [npiNumber, setNpiNumber] = React.useState('');
  const [startWithFreeTrial, setStartWithFreeTrial] = useState(true);
  const [isInvitePopupOpen, setInvitePopupOpen] = useState(false);
  const [isRoleSelectionPopup, setRoleSelectionPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const isRenew = new URLSearchParams(window.location.search).get('isRenew') === 'true' || isLoggedIn;

  const handleNpiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNpiNumber(event.target.value);
  };

  const validateEmail = (email: string): boolean => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : 'Invalid email address');
  };

  const handleFreeTrialChange = (event: { currentTarget: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setStartWithFreeTrial(event.currentTarget.checked);
  };

  const handleNextClick = async () => {
    if (npiNumber === '') {
      toast.error("Please enter a valid NPI number.");
      return;
    }

    if (email === '' || emailError !== '') {
      toast.error("Please enter a valid email address.");
      return;
    }

    const payload = { npi_number: npiNumber, email: email };
    try {
      const response = await npiResponse(medplum, payload);
      const priceId = response?.data?.priceId;
      const role = response?.data?.role;

      if (!priceId) {
        throw new Error("Invalid response data");
      }

      if (window.location.href.includes('isRenew=true')) {
        const renewPayload = { priceId: priceId };
        try {
          const res = await getStripeRenewSession(medplum, renewPayload);
          window.location.href = res.sessionUrl;
        } catch (err) {
          console.error('Error fetching Stripe renew session:', err);
        }
      } else {
        stripeSession(priceId, startWithFreeTrial, role);
        if (response?.data) {
          sessionStorage.setItem('response_data', JSON.stringify(response.data));
        }
      }
    } catch (error: any) {
      if (error?.response) {
        if (error?.response?.status === 404) {
          const isPractitionerExist = error?.response?.data?.isPractitionerExist;
          if (!isPractitionerExist) {
            toast.error(error.response.data.message, { autoClose: 5000 });
            setEmail('');
            return;
          }
          setRoleSelectionPopup(true);

        } else if (error?.response?.status === 409) {
          const isSubscriptionExist = error?.response?.data?.isSubscriptionExist;
          if (isSubscriptionExist) {
            toast.error(error.response.data.message, { autoClose: 15000 });
            setEmail('');
            return;
          }
          toast.error(error?.response?.data?.message, { autoClose: 15000 });
          setEmail('');

        } else {
          toast.error(error?.response?.data?.message);
        }

      } else {
        toast.error("Please try again later.");
      }
    }
  };

  const stripeSession = (stripePriceId: any, startWithFreeTrial: boolean, role: string) => {
    const payload = {
      priceId: stripePriceId,
      isFreeTrial: startWithFreeTrial,
      role: role,
      npiNumber: npiNumber,
      email: email
    }
    createStripeSsession(payload).then((res) => {
      console.log(res)
      window.location.href = res.sessionUrl;
    }).catch((err) => {
      console.log(err);
    });
  }

  const closePopup = () => {
    setInvitePopupOpen(false);
  };

  const handleInviteSubmit = async (medplum: any, details: PractitionerDetails) => {
    try {
      const response: any = await handleNonNpiReasonResponse(medplum, details);

      if (response) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
        setTimeout(() => {
          medplum.signOut();
          window.location.href = '/signin';
        }, 3000);
      } else {
        toast.error(`Failed to send invitation. Please try again later.`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      closePopup();
    }
  };

  const handleRoleSelectionSubmit = async (selectedRole: string) => {
    const payload = {
      role: selectedRole
    }
    sessionStorage.setItem('response_data', JSON.stringify(payload));
    stripeSession('', startWithFreeTrial, selectedRole);
  };

  const openInvitePopup = async () => {
    setInvitePopupOpen(true);
  };

  const handleNpiLookup = () => {
    window.open("https://npiregistry.cms.hhs.gov/", "_blank");
  };

  return (
    <Box >
      <Grid className={classes.signUpPage}>
        <Grid.Col span={12} className={classes.desktopOnly}>
          <div className={classes.pmhLogo}><img src="../../img/logo.png" alt="PMHScribe Logo" width="200" /></div>
        </Grid.Col>

        <Grid.Col span={12} className={classes.trialOptionContainer}>
          {!isRenew && (<Checkbox
            label="Start with free trial"
            checked={startWithFreeTrial}
            onChange={handleFreeTrialChange}
            mb={10}
            classNames={{ label: classes.freeTrialLabel }}
          />)}
        </Grid.Col>

        <Grid.Col span={8} className={classes.para}>
          <Title order={4} color='#3e3d3d'>
            Welcome!
          </Title>
          <Text className={classes.npiVerificationText}>
            To access the platform, please confirm that you are a licensed healthcare provider by entering your
            National Provider Identifier (NPI).
          </Text>
        </Grid.Col>

        <Grid.Col span={12} className={classes.npiSect}>
          <div>
            <div className={classes.labelContainer}>
              <Text className={classes.label}>NPI Number</Text>
              <Text
                className={classes.lookupText}
                onClick={handleNpiLookup}
              >
                Lookup My NPI
              </Text>
            </div>
            <TextInput
              value={npiNumber}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleNpiChange(e);
                }
              }}
              maxLength={10}
              placeholder="Enter your NPI number"
              className={classes.inputWidth}
            />
            <div >
              <Text className={classes.label} mt={15}>Email Address</Text>
            </div>
            <div className={classes.flexContainer}>
              <TextInput
                value={email}
                onChange={handleEmailChange}
                placeholder="Name@example.com"
                className={classes.inputWidth}
                error={emailError}
              />
              <Button mt={22} ml={20} onClick={handleNextClick} className={classes.nextBtn}>
                Next
              </Button>
            </div>
          </div>
          <div className={classes.npiRequest}>
            <Anchor mt={22} className={classes.anchorText} onClick={openInvitePopup}>I don’t have an NPI - Request an Account</Anchor>
          </div>
        </Grid.Col>

        <Grid.Col span={12} mt={22} className={classes.colSect}>
          {!isRenew && (
            <Anchor href="/sign-in" className={classes.underlineText}>
              I already have an account. Sign in.
            </Anchor>
          )}
          <hr className={classes.borderTop} />
          <div className={classes.privacyText}>
            <div className={classes.flexDiv}>
              <img src="../../img/HIPAA.png" alt="HIPAA Logo" width="80" /></div>
            <Text >Your data is kept secure under HIPAA, HITECH, and Mental Health Privacy laws. You may keep patient health information and records in our system, sync them to your electronic health record, or choose not to enter patient details. By signing up for PMHScribe, you agree to our <Anchor href='https://pmhscribe.com/privacy-policy/' target='_blank'>Privacy Policy</Anchor> and <Anchor href='https://pmhscribe.com/terms-and-conditions/' target='_blank'>Terms of Use</Anchor>.</Text>
          </div>
        </Grid.Col>
      </Grid>

      <InvitePopup
        isOpen={isInvitePopupOpen}
        onClose={closePopup}
        onSubmit={(details) => handleInviteSubmit(medplum, details)}
        header="Non-NPI user details"
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
        isLoading={false}
      />

      <RoleSelectionPopup
        isOpen={isRoleSelectionPopup}
        onClose={() => setRoleSelectionPopup(false)}
        onSubmit={(selectedRole) => handleRoleSelectionSubmit(selectedRole)}
        submitButtonLabel="Submit"
        cancelButtonLabel="Cancel"
      />
    </Box>
  )
}

export default SignUp

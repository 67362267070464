import React, { useState } from 'react';
import { Modal, Button, Grid, Text, TextInput } from '@mantine/core';
import couponStyles from './CouponReferral.module.css';
import { IconCopy } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { getCouponCode } from '../utils/util';
import { toast } from 'react-toastify';

interface CouponReferralProps {
    opened: boolean;
    onClose: () => void;
    onSubmit?: (email: string) => void;
}

const CouponReferral: React.FC<CouponReferralProps> = ({ opened, onClose, onSubmit }) => {
    const [email, setEmail] = useState('');
    const medplum = useMedplum();
    const [couponCode, setCouponCode] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        debugger
        e.preventDefault();

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        setLoading(true);
        setCouponCode(null);
        try {
            const response = await getCouponCode(medplum, email);
            setCouponCode(response?.code || 'No Code Available');
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error || 'Error generating coupon code';
            toast.error(errorMessage);
            setCouponCode(null);
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = () => {
        if (couponCode) {
            navigator.clipboard.writeText(couponCode);
            toast.success('Coupon code copied to clipboard!');
        }
    };

    const steps = [
        { number: 1, text: 'Share a unique referral code with your colleague, which is generated specifically for them.' },
        { number: 2, text: 'Your colleague will receive a 50% discount on their first paid subscription when they sign up using the same email.' },
        { number: 3, text: 'You will also receive a similar discount on your next paid subscription as a reward.' }
    ];

    return (
        <Modal opened={opened} onClose={onClose} className='coupon-modal'>
            <Grid pl={15} >
                <Grid.Col span={12} className={couponStyles.giftImg}>
                    <img src="../../../../img/icons/coupon-gift.svg" alt="Download" width='110' />
                </Grid.Col>

                <Grid.Col span={12}>
                    <Text className={couponStyles.couponText}>Refer a Colleague! Give 50%, Get 50%!</Text>
                    <Text className={couponStyles.couponSubText}>Introduce a colleague to PMHScribe, and both of you will receive 50% in PMHScribe credit.</Text>
                </Grid.Col>

                <Grid.Col span={12} className={couponStyles.gridBox}>
                    <TextInput
                        label="Your Colleague email"
                        classNames={{ label: couponStyles.inputLabel }}
                        placeholder="Enter your colleague's email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            if (!e.target.value) setCouponCode(null);
                        }}
                        required
                        className={couponStyles.inputWidth}
                    />
                    <Button
                        type="submit"
                        className={couponStyles.submitBtn}
                        mt={42}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Generating...' : 'Submit'}
                    </Button>
                </Grid.Col>

                {couponCode && (
                    <Grid.Col span={12} className={couponStyles.couponCodeBox}>
                        <div className={couponStyles.couponCodeDiv}>
                            <span className={couponStyles.couponCodeText}>
                                {couponCode || 'Waiting for code...'}
                            </span>
                        </div>

                        <Button mt={20} className={couponStyles.copyBtn} onClick={handleCopy} disabled={!couponCode}>
                            <IconCopy />&nbsp; Copy Coupon Code
                        </Button>
                    </Grid.Col>
                )}
            </Grid>

            <hr className={couponStyles.lineBorder} />

            <Grid pl={15} pb={30}>
                <Grid.Col span={12}>
                    <Text className={couponStyles.sectText}>How It Works</Text>
                </Grid.Col>
                {steps.map(({ number, text }) => (
                    <Grid.Col key={number} span={4} className={couponStyles.gridBox}>
                        <div className={couponStyles.stepBox}>
                            <Text className={couponStyles.stepText}>{number}</Text>
                            <Text className={couponStyles.stepSubText}>{text}</Text>
                        </div>
                    </Grid.Col>
                ))}
            </Grid>
        </Modal>
    );
};

export default CouponReferral;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Center, Pagination, Table, Text, Tooltip, createStyles } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import ConfirmBox from '../patients/ConfirmBox';
import { deleteSessionAndNotes, archiveSessionResponse } from '../utils/util';
import { toast } from 'react-toastify';

const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '100%',
    overflow: 'auto',
    textAlign: 'left',
    marginBottom: '20px',
  },

  tr: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    },
  },

  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

export function Sessions(): JSX.Element | null {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const resourceId = useParams().id;
  const [appointmentResponse, setAppointmentResponse] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const resource = window.location.pathname.includes('Practitioner') ? 'Practitioner' : 'Patient';
  const [deletingUserId, setDeletingUserId] = useState<string>('');
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);


  const getSessionsList = async (page: number) => {
    try {
      const token = await medplum.getAccessToken();
      const offset = (page - 1) * perPage;
      const resourceType = resource === 'Practitioner' ? `actor=Practitioner/${resourceId}` : `patient=${resourceId}`;

      const response = await axios.get(
        `intg/get-patient-sessions?${resourceType}&_offset=${offset}&identifier:not=unknown&_count=${perPage}&_sort=-_lastUpdated&_total=accurate&status=checked-in`,
        {
          headers: {
            Accept: 'application/fhir+json',
            Authorization: `Bearer ${token}`,
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );

      setTotalItems(response.data.total);
      setAppointmentResponse(response.data.entry || []);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setAppointmentResponse([]);
    }
  };

  useEffect(() => {
    getSessionsList(currentPage);
  }, [currentPage]);

  const handleRowClick = (row: any) => {
    if (row.id) {
      navigate(`/Appointment/${row.id}/details`);
    }
  };

  const handleDeleteIconClick = (resource: any) => {
    setDeletingUserId(resource.id);
    setShowConfirmBox(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    try {
      let response;
      if (medplum.isSuperAdmin()) {
        response = await deleteSessionAndNotes(medplum, deletingUserId);
      } else {
        if (deletingUserId) {
          const payload = {
            sessionId: deletingUserId,
          };
          response = await archiveSessionResponse(medplum, payload);
        }
      }
      if (response) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        getSessionsList(currentPage);
      }
    } catch (error) {
      console.error('Error deleting session:', error);
      toast.error('Failed to delete session', { position: toast.POSITION.TOP_RIGHT });
    } finally {
      setIsDeletingLoader(false);
      setShowConfirmBox(false);
    }
  };

  return (
    <div className="resource-panel sessions-resource-panel">
      <div className="resource-list">
        {(resource === 'Practitioner' || resource === 'Patient') && (
          <div className='sessions-tab-container'>
            <Table>
              <thead>
                <tr>
                  <th className="table-heading">Session Date & Time</th>
                  <th className="table-heading">Session Description</th>
                  <th className="table-heading">Patient</th>
                  {medplum.isSuperAdmin() && <th className="table-heading">Tokens/Cost</th>}
                  <th className="table-heading" style={{ textAlign: 'center' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {appointmentResponse.length > 0 ? (
                  appointmentResponse.map((data: any, index: number) => (
                    <tr key={index} className={classes.tr} onClick={() => handleRowClick(data.resource)}>
                      <td>{data?.resource?.start || ''}</td>
                      <td>{data?.resource?.description || ''}</td>
                      <td>{data.resource?.participant?.find((p: any) => p.actor?.reference?.includes('Patient'))?.actor?.display || ''}</td>
                      {medplum.isSuperAdmin() && <td>
                        {data?.resource?.numberOfTokens || ''} / ${Number(data?.resource?.indicativeCost || 0).toFixed(5)}
                      </td>}
                      <td className="actions" style={{ textAlign: 'center' }}>
                        <Tooltip label="Delete Session" withArrow position="top">
                          <IconTrash
                            size={18}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDeleteIconClick(data.resource);
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      <Text>No Data</Text>
                    </td>
                  </tr>
                )}
                <ConfirmBox
                  isOpen={showConfirmBox}
                  onClose={() => setShowConfirmBox(false)}
                  onConfirm={() => {
                    handleConfirmDelete();
                  }}
                  confirm={'Confirm'}
                  cancel="Cancel"
                  message={`Are you sure you want to delete this session?`}
                  isLoader={isDeletingLoader} additionalText={''} patient={undefined} />
              </tbody>
            </Table>
          </div>
        )
        }

        <Center m="md" p="md" style={{ justifyContent: 'flex-end' }}>
          <Pagination
            className="pagination"
            value={currentPage}
            total={Math.ceil(totalItems / perPage)}
            onChange={setCurrentPage}
          />
        </Center>
      </div>
    </div >

  );
}

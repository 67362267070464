/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { getConfig } from '../config';

const config = getConfig();
const axiosServices = axios.create({ baseURL: config?.baseUrl });


export default axiosServices;

import { Patient, Reference } from '@medplum/fhirtypes';
import { HumanNameDisplay, ResourceAvatar, useResource } from '@medplum/react';
import React from 'react';
import { InfoBar } from './InfoBar';
import { getDefaultColor } from './PatientHeader.utils';
import { calculateAge } from '@medplum/core';
import { Text } from '@mantine/core';

export interface PatientHeaderProps {
  patient: Patient | Reference<Patient>;
}

export function PatientHeader(props: PatientHeaderProps): JSX.Element | null {
  const patient = useResource(props.patient);
  if (!patient) {
    return null;
  }

  function calculateAgeString(birthDateStr: string, endDateStr?: string): string | undefined {
    const { years, months, days } = calculateAge(birthDateStr, endDateStr);
    if (years >= 2) {
      return years.toString() + 'Y';
    } else if (months >= 1) {
      return months.toString() + 'M';
    } else {
      return days.toString() + 'D';
    }
  }

  const parseDateString = (dateString: string): string => {
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  return (
    <InfoBar>
      <ResourceAvatar value={patient} size="lg" color={getDefaultColor(patient)} />
      <InfoBar.Entry>
        <InfoBar.Key>Name</InfoBar.Key>
        <InfoBar.Value>
          <Text>
            {patient.name ? <HumanNameDisplay value={patient.name[0]} options={{ use: false }} /> : '[blank]'}
          </Text>
        </InfoBar.Value>
      </InfoBar.Entry>
      {patient.birthDate && (
        <>
          <InfoBar.Entry>
            <InfoBar.Key>DoB</InfoBar.Key>
            <InfoBar.Value>{parseDateString(patient.birthDate)}</InfoBar.Value>
          </InfoBar.Entry>
          <InfoBar.Entry>
            <InfoBar.Key>Age</InfoBar.Key>
            <InfoBar.Value>{calculateAgeString(patient.birthDate)}</InfoBar.Value>
          </InfoBar.Entry>
        </>
      )}
      <InfoBar.Entry>
        <InfoBar.Key>Gender</InfoBar.Key>
        <InfoBar.Value>
          {patient.gender?.toLowerCase() === "male" || patient.gender?.toLowerCase() === "female"
            ? patient.gender.charAt(0).toUpperCase() + patient.gender.slice(1)
            : "None (Neutral)"}
        </InfoBar.Value>
      </InfoBar.Entry>
      {patient?.address?.[0]?.state && (
        <InfoBar.Entry>
          <InfoBar.Key>State</InfoBar.Key>
          <InfoBar.Value>{patient?.address?.[0]?.state}</InfoBar.Value>
        </InfoBar.Entry>
      )}
      {patient.identifier?.map((identifier) => (
        <InfoBar.Entry key={`${identifier?.system}-${identifier?.value}`}>
          <InfoBar.Key>{identifier?.system}</InfoBar.Key>
          <InfoBar.Value>{identifier?.value}</InfoBar.Value>
        </InfoBar.Entry>
      ))}
    </InfoBar>
  );
}
